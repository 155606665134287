import React from "react";
import { SocialIcon } from "react-social-icons";

function SocialCard({ url }: { url: string }) {
  const getBackgroundImage = (url: string) => {
    let backgroundImage = "";
    if (url.includes("discord")) {
      backgroundImage =
        "url('carousel_assets/discord.png') no-repeat center center / cover";
    } else if (url.includes("reddit")) {
      backgroundImage =
        "url('carousel_assets/reddit.png') no-repeat center center / cover";
    } else if (url.includes("x.com")) {
      backgroundImage =
        "linear-gradient(to right, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('X_theme.png') no-repeat center center / cover";
    }
    return backgroundImage; // default, no background image
  };

  return (
    <div
      className="flex flex-col justify-center items-center w-full rounded-lg cursor-pointer"
      style={{ background: getBackgroundImage(url) }}
      onClick={() => window.open(url, "_blank")}
    >
      <h3 style={styles.header}>
        {url.includes("discord")
          ? ""
          : url.includes("reddit")
            ? ""
            : "Stay updated"}{" "}
      </h3>
      <SocialIcon
        url={url}
        bgColor="transparent"
        style={{
          ...styles.socialIconStyle,
        }}
      />
    </div>
  );
}

const styles = {
  socialIconStyle: {
    height: 90,
    width: 90,

    marginBottom: "4rem",
  },
  header: {
    color: "var(--primary-text-color)",
    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    // margin: "auto",
    alignSelf: "center",
  },
};
export default SocialCard;
