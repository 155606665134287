import React, { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/joy";
import { Pencil, Trash, BotMessageSquare } from "lucide-react";

import { useNavigate } from "react-router-dom";
import { CreatedCharactersProps } from "../ProfilePage/CreatedCharacters";
import { Character, UserAssets } from "../../types";
import { deleteCharacter } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
import LockIcon from "@mui/icons-material/Lock";
import "./Character.css";

export interface CharacterProfileProps {
  character_id: string;
  name: string;
  interactions: number;
  num_saves: number;
  isOther?: boolean;
  image_url: string;
  is_public: boolean;
  setCharacters: React.Dispatch<React.SetStateAction<CreatedCharactersProps[]>>;
  setUserAssets: React.Dispatch<React.SetStateAction<UserAssets>>;
}

export const CharacterProfile: React.FC<CharacterProfileProps> = ({
  character_id,
  name,
  interactions,
  num_saves,
  isOther,
  is_public,
  image_url,
  setCharacters,
  setUserAssets,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("useContext must be used within a PlatformContext");
  }
  const username = context.username;
  const navigate = useNavigate();
  const handleDelete = async () => {
    console.log("Deleting character with ID:", character_id);
    const deleteChat = async () => {
      const request_body = {
        item: "character",
        character_id: character_id,
      };

      try {
        const response = await makeRequest(
          deleteCharacter(username, character_id)
        );
        console.log("Response:", response);
      } catch (error) {
        console.error("Error deleting character:", error);
      }
    };
    setCharacters((prev) => {
      return prev.filter(
        (character) => character.character_id !== character_id
      );
    });
    setUserAssets((prev) => {
      return {
        ...prev,
        characters: prev.characters.filter(
          (character) => character.character_id !== character_id
        ),
      };
    });
    deleteChat();
    setOpen(false);
  };
  return (
    <div className="profile-character-card fastFadeInUp flex flex-col items-center">
      <div className="flex flex-col p-2 space-y-2 ">
        <img
          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${Date.now()}`}
          alt={name}
          onError={(e) => {
            const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
            target.onerror = null; // prevents looping
            target.src =
              "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
          }}
          className="aspect-square rounded-lg w-full"
        />
        <h3 className="p-0 text-center text-sm">
          {name}{" "}
          {String(is_public).toLowerCase() === "false" ? <LockIcon /> : null}
        </h3>
        <p className="p-0 text-sm flex flex-row items-center m-auto gap-2">
          <BotMessageSquare />
          {interactions}
        </p>
        <Button
          variant="soft"
          color="danger"
          onClick={() => navigate(`/chat/${character_id}`)}
        >
          Chat
        </Button>
        {!isOther && (
          <div className="flex flex-row gap-2 m-auto">
            <Pencil
              cursor="pointer"
              onClick={() => navigate(`/create/${character_id}`)}
            />

            <Trash color="red" cursor="pointer" onClick={handleClickOpen} />

            <Dialog className="delete-popup" open={open} onClose={handleClose}>
              <DialogTitle>{"Confirm Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText color={"var(--primary-text-color"}>
                  Are you sure you want to delete this character?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} color="danger">
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
};
