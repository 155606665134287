import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from "react";
import { IconButton, containerClasses } from "@mui/material";
import { debounce } from "../Misc/SearchOverlay";
import { X } from "lucide-react";
import {
  Modal,
  ModalDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  List,
  ListItem,
  Typography,
  ListItemDecorator,
  ListItemContent,
  Avatar,
  Box,
  CircularProgress,
  Chip,
  Table,
} from "@mui/joy";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character, SelectedCharacterProps } from "../../types";
import MapCarousel from "./MapCarousel";
import { CircleHelp } from "lucide-react";
import { Search } from "lucide-react";

interface CreateTownDialogProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  townName: string;
  setTownName: (name: string) => void;
  selectedCharacters: SelectedCharacterProps[];
  handleCharacterChange: (character: SelectedCharacterProps) => void;
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
  // displaySelectedCharacters: Character[];
  createGotoTown: (townName: string) => void;
}

const townMaps = [
  // { name: "A16Z AI Town", image: "/gentle-obj.png" },
  { name: "4WALL HQ", image: "/AlphaMap.png" },
  // { name: "Random Town", image: "/darksunset.png" },
  // { name: "Random Town 2", image: "/darksunset.png" },
  // { name: "Random Town 3", image: "/darksunset.png" },
];
const CreateTownDialog: React.FC<CreateTownDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  townName,
  setTownName,
  selectedCharacters,
  handleCharacterChange,
  setSelectedCharacters,

  createGotoTown,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const context = useContext(PlatformContext);
  const [selectedMap, setSelectedMap] = useState(townMaps[0]);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { username, featuredLists, NSFW } = context;
  const defaultPlan =
    "You are in 4Wall Spot, a digital town where you have the ability to walk around and interact with users and other characters.";

  function parseSearchResults(searchResults: any) {
    // Extract the hits from the search results
    const hits = searchResults.hits.hit;

    // Log those that are filtered out for debugging
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public, tags } = hit.fields;
      const isDeleted = is_deleted !== "0";
      const isNotPublic = is_public !== "1";
      const containsNsfw = tags.includes("NSFW");
      if (isDeleted || isNotPublic || (!NSFW && containsNsfw)) {
        console.log(
          `Character skipped (ID: ${hit.fields.character_id}, Name: ${hit.fields.name}) because is_deleted=${isDeleted}, is_public=${isNotPublic}, and contains NSFW=${containsNsfw}`
        );
        return false;
      }
      return true;
    });

    // Map over the hits array to transform each search hit into a Character object
    const characters = filteredHits.map((hit: any) => {
      const fields = hit.fields;

      return {
        bio: fields.bio,
        char_message_bg_color: fields.char_message_bg_color,
        char_text_color: fields.char_text_color,
        character_id: fields.character_id,
        chat_background_color: fields.chat_background_color,
        creator: fields.creator,
        user_message_bg_color: fields.user_message_bg_color,
        user_text_color: fields.user_text_color,
        name: fields.name,
        image_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/display_picture`,
        chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/chat_background`,
        interactions: fields.num_interactions,
        num_saves: fields.num_favorites,
        tags: fields.tags,

        // ... map all other fields to their respective Character properties
      };
    });

    return characters;
  }

  const fetchSearchResults = (value: string) => {
    setIsLoading(true);

    const apiUrl = `${process.env.REACT_APP_SEARCH_API}?q=${encodeURIComponent(
      value + "*"
    )}&fq=(and is_public:'1' is_deleted:'0')&start=0&size=50`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setSearchResults(newCharacters);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Search error:", error);
        setIsLoading(false);
      });
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);
  const handleCreateTown = () => {
    if (!townName.trim()) {
      alert("Spot name cannot be empty");
      return;
    }
    if (selectedCharacters.length < 2) {
      alert("You must select at least 2 characters");
      return;
    }
    createGotoTown(townName);
  };

  const updatePlan = (character: SelectedCharacterProps, plan: string) => {
    const updatedCharacters = selectedCharacters.map((c) =>
      c.character_id === character.character_id ? { ...c, plan } : c
    );
    setSelectedCharacters(updatedCharacters);
  };
  const displayList = searchTerm.trim()
    ? searchResults
    : [
        ...featuredLists.anime,
        ...featuredLists.isekaiRPG,
        ...featuredLists.gaming,
        ...featuredLists.moviesTV,
      ];

  return (
    <Modal
      // sx={styles.dialog}
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <ModalDialog style={styles.dialog}>
        <Modal open={helpOpen} onClose={() => setHelpOpen(false)}>
          <ModalDialog
            style={styles.helperDialog}
            className="!bg-neutral-900 !rounded-xl"
          >
            <X
              onClick={() => setHelpOpen(false)}
              style={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
                cursor: "pointer",
                color: "var(--orange-brand-accent)",
              }}
            />
            <DialogTitle>
              <h2 className="text-fourwall-orange">Create a New Spot</h2>
            </DialogTitle>
            <DialogContent>
              <div className="text-white">
                <ul style={{ padding: "0", listStyleType: "none" }}>
                  <li style={{ padding: "10px 0" }}>
                    <strong>Name Your Spot:</strong> Enter a unique and fun name
                    for your Spot in the "Spot Name" field.
                  </li>

                  <li style={{ padding: "10px 0" }}>
                    <strong>Add Characters:</strong> Select up to 5 characters
                    for your Spot. Choose from the featured list or find your
                    favorites using the search bar.
                  </li>

                  <li style={{ padding: "10px 0" }}>
                    <strong>Provide a Plan:</strong> For each character, you can
                    provide an objective or goal for them in the Spot.
                  </li>

                  <li style={{ padding: "10px 0" }}>
                    <strong>Select a Map:</strong> Currently, we have one map
                    available, but more maps will be added soon. Eventually,
                    you'll also be able to create your own maps.
                  </li>

                  <li style={{ padding: "10px 0" }}>
                    <strong>Create Your Spot:</strong> Once you've named your
                    Spot, added characters, and selected a map, click "Create"
                    to bring your Spot to life!
                  </li>
                </ul>
              </div>
            </DialogContent>
          </ModalDialog>
        </Modal>
        <DialogTitle
          level="h2"
          style={{
            fontFamily: "var(--font_b)",
          }}
        >
          Create a New Spot
          <CircleHelp
            color="white"
            size={32}
            onClick={() => setHelpOpen(true)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "2rem 0 ",
          }}
        >
          <div style={styles.leftSide}>
            <Input
              startDecorator={<Search />}
              id="searchCharacters"
              placeholder="Search Characters"
              type="search"
              fullWidth
              variant="outlined"
              value={searchTerm}
              style={styles.searchInput}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus={false}
            />
            {!searchTerm.trim() && <h3>Featured Characters</h3>}
            <Box sx={{ height: 400, overflow: "auto " }}>
              <List
                sx={{
                  "--ListItemDecorator-size": "56px",

                  overflowY: "auto",
                }}
              >
                {isLoading ? (
                  <CircularProgress size="md" />
                ) : (
                  displayList.map((character) => (
                    <ListItem
                      key={character.character_id}
                      onClick={() =>
                        handleCharacterChange({
                          character_id: character.character_id,
                          plan: defaultPlan,
                          image_url: character.image_url,
                          name: character.name,
                        })
                      }
                      sx={{
                        backgroundColor: selectedCharacters.some(
                          (selectedCharacter) =>
                            selectedCharacter.character_id ===
                            character.character_id
                        )
                          ? "rgba(255, 255, 255, 0.1)"
                          : "transparent",
                      }}
                    >
                      <ListItemDecorator>
                        <Avatar
                          alt={character.name}
                          src={character.image_url}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <p style={styles.sidebarText}>{character.name}</p>
                      </ListItemContent>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          </div>
          <div style={styles.rightSide}>
            <Input
              autoFocus
              id="townName"
              placeholder="Spot Name"
              type="text"
              fullWidth
              variant="outlined"
              value={townName}
              style={{ ...styles.townNameInput, width: "100%" }}
              onChange={(e) => setTownName(e.target.value.slice(0, 50))}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                padding: "1rem",
              }}
            >
              <Table>
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "40%",
                        color: "var(--orange-brand-accent)",
                        backgroundColor: "transparent",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        width: "60%",
                        color: "var(--orange-brand-accent)",
                        backgroundColor: "transparent",
                      }}
                    >
                      Character Plan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCharacters.map((character, index) => (
                    <tr key={character.character_id}>
                      <td>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <X
                            size={16}
                            color="var(--primary-text-color)"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleCharacterChange({
                                character_id: character.character_id,
                                plan: defaultPlan,
                                image_url: character.image_url,
                                name: character.name,
                              })
                            }
                          />
                          <Avatar src={character.image_url} />
                          <Typography
                            sx={{
                              fontFamily: "var(--font_b)",
                              color: "var(--primary-text-color)",
                            }}
                          >
                            {character.name}
                          </Typography>
                        </Box>
                      </td>
                      <td>
                        <Input
                          type="text"
                          variant="soft"
                          placeholder="Plan"
                          sx={{
                            backgroundColor: "black",
                            color: "var(--primary-text-color)",
                            width: "100%",
                          }}
                          value={character.plan}
                          onChange={(e) => {
                            const newValue = e.target.value.slice(0, 200);
                            updatePlan(character, newValue);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
            <div style={styles.mapSection}>
              <MapCarousel maps={townMaps} handleMapSelect={setSelectedMap} />
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ margin: "auto" }}>
          <Button color="danger" onClick={() => setDialogOpen(false)}>
            CANCEL
          </Button>
          <Button color="primary" onClick={handleCreateTown}>
            CREATE
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
const styles: { [key: string]: CSSProperties } = {
  dialog: {
    margin: "auto",
    border: "none",
    backgroundColor: "rgba(43, 43, 43, 0.9)",
    fontFamily: "var(--font_b)",
    color: "var(--primary-text-color)",
  },
  stuffContainer: {
    display: "flex",
    flexDirection: "row",
    color: "var(--orange-brand-accent)",
  },
  leftSide: {
    width: "25rem",
    height: "100%",
    minHeight: "100%",
    marginRight: "1rem",
    color: "var(--orange-brand-accent)",
    borderRight: "1px solid rgba(255, 255, 255, 0.2)",
  },
  rightSide: {
    width: "40rem",
    marginLeft: "1rem",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },

  townNameInput: {
    width: "20rem",
    backgroundColor: "var(--primary-background-color)",
    color: "var(--primary-text-color)",
  },
  searchInput: {
    width: "20rem",
    backgroundColor: "var(--primary-background-color)",
    color: "var(--primary-text-color)",
  },
  sidebarText: {
    fontSize: "0.9rem",
    color: "var(--primary-text-color)",
    fontFamily: "var(--font_b)",
  },

  mapSection: {
    marginTop: "auto",
    width: "100%",
  },
  displayCharacterContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  helperDialog: {
    backgroundColor: "var(--blue-brand-accent)",
    fontFamily: "var(--font_b)",
    border: "none",
    width: "50%",
  },
};

export default CreateTownDialog;
