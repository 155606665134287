import { useState, useContext } from "react";
import { Character } from "../../types";
import "./Character.css";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MessageSquareText } from "lucide-react";
import { useAuth0 } from "@auth0/auth0-react";

export interface CharacterProps {
  character: Character;
  onClick?: () => void;
  theme?: boolean;
  explore?: boolean;
}

function CharacterTrending({
  character,
  onClick,
  theme,
  explore,
}: CharacterProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = window.innerWidth < 768;
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const handleOtherUserClick = (event: any) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/profile/${encodeURIComponent(character.creator)}`,
        },
      });
    }

    event.stopPropagation();
    navigate(`/profile/${encodeURIComponent(character.creator)}`);
  };
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  function formatInteractionsCount(count: number) {
    if (count < 1000) return count; // Return the original count if less than 1000
    return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + "k"; // Otherwise, format as '1.2k', '3k', etc.
  }
  return (
    <div
      className="trending-character-card fadeInUp !w-full relative overflow-hidden"
      key={character.character_id}
      onClick={onClick}
    >
      {!isLoaded && <Skeleton variant="rectangular" width={210} height={118} />}
      <img
        src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
        alt={character.name}
        onLoad={() => setIsLoaded(true)}
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.onerror = null;
          target.src =
            "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
        }}
        className="w-full h-full object-cover "
        style={{
          filter: "contrast(1.1) saturate(1.1) brightness(1.04)",
        }}
      />
      <div className="absolute inset-0  z-20 font-main">
        <div className="flex flex-col justify-end h-full p-3">
          <h3 className="md:text-[1rem] text-[0.8rem] font-bold  truncate m-0 text-orange-100">
            {character.name}
          </h3>
          <p className="md:text-[0.6rem] text-[0.5rem] text-orange-300  h-7 overflow-hidden">
            {character.bio || "\u00A0"}
          </p>
          <div className="flex justify-between items-center md:text-[0.6em] text-[0.5rem] text-orange-400 mb-2">
            <p
              className="hover:underline cursor-pointer"
              onClick={(e) => handleOtherUserClick(e)}
            >
              @{character.creator}
            </p>
            <div className="flex items-center space-x-1">
              <MessageSquareText size={14} />
              <span>{formatInteractionsCount(character.interactions)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CharacterTrending;
