import { useRef, useState, useEffect } from "react";

interface Size {
  width: number;
  height: number;
}

function useResizeObserver(): [React.RefObject<HTMLDivElement>, Size] {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const observeTarget = ref.current;
    if (observeTarget) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries.length === 0 || entries[0].target !== observeTarget) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      });
      resizeObserver.observe(observeTarget);

      return () => resizeObserver.unobserve(observeTarget);
    }
  }, [ref]);

  return [ref, size];
}

export default useResizeObserver;
