import React, { useState, useEffect } from "react";
import { PersonaDataProps, UserAssets } from "../../types";
import AddIcon from "@mui/icons-material/Add";

import CreateUpdatePersona from "./CreateUpdatePersona";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Ellipsis } from "lucide-react";
import {
  deletePersona,
  updatePersona,
  getUserAssets,
} from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { Trash2, Pencil, Check, X } from "lucide-react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Dropdown,
  List,
  ListItem,
  ListItemButton,
  Avatar,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/joy";
import { getRandomColor, getGradient } from "./utils";

function Personas() {
  const [showCreatePersona, setShowCreatePersona] = useState(false);
  const [persona, setPersona] = useState<PersonaDataProps>({
    name: "",
    description: "",
    persona_id: "",
    default: false,
  });
  const [personas, setPersonas] = useState<PersonaDataProps[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const showAlert = (message: string, severity: "success" | "error") => {
    setAlertInfo({ open: true, message, severity });
  };

  // Helper function to close alert
  const closeAlert = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };

  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { userAssets, setUserAssets, username } = context;
  const { user, isAuthenticated, isLoading } = useAuth0();

  const handleCloseForm = () => {
    setShowCreatePersona(false);
  };
  const handlePersonaCreateUpdate = (persona_id: string) => {
    if (persona_id !== "") {
      setPersona(
        personas.find((p) => p.persona_id === persona_id) || {
          name: "",
          description: "",
          persona_id: "",
          default: false,
        }
      );
    } else {
      setPersona({
        name: "",
        description: "",
        persona_id: "",
        default: false,
      });
    }

    setShowCreatePersona(true);
  };

  const fetchPersonas = async () => {
    if (isAuthenticated && user) {
      const data = userAssets.personas;
      setPersonas(data);
    }
  };
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      window.location.href = "/";
    } else if (!isLoading && isAuthenticated) {
      fetchPersonas();
    }
    setPageLoading(false);
  }, [isLoading, userAssets.characters]);

  const personaColors = userAssets.personas.reduce(
    (acc: Record<string, string>, persona: PersonaDataProps) => {
      acc[persona.persona_id] = getRandomColor();
      return acc;
    },
    {} as Record<string, string>
  );

  const makeRequest = useRequest();
  const refetchUserAssets = async (username: string) => {
    const userRequest = getUserAssets(username, "false");
    try {
      const response = await makeRequest<UserAssets, any>(userRequest);
      setUserAssets(response);
    } catch (error) {
      console.error("There was an error fetching user assets:", error);
    }
  };
  const handleDeletePersona = async (persona_id: string) => {
    // const response = await makeRequest<any, any>(deletePersona(persona_id));
    // console.log("delete response", response);
    try {
      await makeRequest<any, any>(deletePersona(persona_id));
      setAlertInfo({
        open: true,
        message: "Persona deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting persona:", error);
      setAlertInfo({
        open: true,
        message: "Failed to delete persona",
        severity: "error",
      });
    }
    await refetchUserAssets(username);
    fetchPersonas();
  };
  const handleMakeDefault = async (persona_id: string) => {
    const personaFocused = personas.find((p) => p.persona_id === persona_id);
    if (!personaFocused) {
      return;
    }
    const request_body = {
      persona: {
        name: personaFocused.name,
        description: personaFocused.description,
        persona_id: personaFocused.persona_id,
        default: true,
        username: username,
      },
    };
    try {
      const response = await makeRequest<any, any>(
        updatePersona(persona_id),
        request_body
      );
      console.log(response);

      setAlertInfo({
        open: true,
        message: "Persona made default successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("There was an error making persona default:", error);
      setAlertInfo({
        open: true,
        message: "Failed to make persona default",
        severity: "error",
      });
    }
    await refetchUserAssets(username);
  };
  if (pageLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress className="!text-orange-500" />
      </div>
    );
  }
  return (
    <>
      <div className="flex flex-row flex-wrap justify-center items-center">
        <Button
          color="primary"
          size="lg"
          className="!rounded-full !font-main !bg-neutral-800 !border-none !outline-none !m-2  hover:!bg-neutral-700 !transition-all !duration-100"
          startDecorator={<AddIcon />}
          onClick={() => handlePersonaCreateUpdate("")}
        >
          Create a persona
        </Button>
      </div>

      <div className="flex flex-row flex-wrap w-full justify-center items-center ">
        <CreateUpdatePersona
          persona={persona}
          showCreatePersona={showCreatePersona}
          setShowCreatePersona={setShowCreatePersona}
          handleCloseForm={handleCloseForm}
          setPersona={setPersona}
          setPersonas={setPersonas}
          setUserAssets={setUserAssets}
        />
        <List className="w-[50%] m-auto  bg-neutral-900/70 !rounded-xl">
          {personas
            .filter((pers) => !pers.is_deleted)
            .map((pers) => (
              <ListItemButton
                key={pers.persona_id}
                className="!text-orange-100 hover:!bg-neutral-700 !m-2 !rounded-lg"
              >
                <Avatar
                  src={`${process.env.REACT_APP_ASSETS_BUCKET}personas/${pers.persona_id}/display_picture.jpg?${Date.now()}`}
                  sx={{
                    background: getGradient(personaColors[pers.persona_id]),
                  }}
                >
                  {pers.name.charAt(0)}
                </Avatar>

                <p className="!text-white !text-md !font-main">{pers.name}</p>
                {pers.default && (
                  <p className="!text-orange-500 !text-md !font-main">
                    Default
                  </p>
                )}
                <div className="flex-grow"></div>
                <Dropdown
                  className="ml-auto"
                  // onClick={(e: any) => e.stopPropagation()}
                >
                  <MenuButton className="!p-2 !transition-all !duration-100 !text-orange-500 hover:!bg-neutral-900 !rounded-full !border-none">
                    <Ellipsis size={20} />
                  </MenuButton>
                  <Menu className="!bg-neutral-800 !border-none !rounded-lg !items-left">
                    <MenuItem
                      className="!text-orange-100 !m-1 !p-2 hover:!bg-neutral-700 !rounded-lg"
                      onClick={() => handlePersonaCreateUpdate(pers.persona_id)}
                    >
                      <Pencil size={20} />
                      Edit
                    </MenuItem>
                    <MenuItem
                      className="!text-orange-100 !m-1 !p-2 hover:!bg-neutral-700 !rounded-lg"
                      onClick={() => {
                        handleDeletePersona(pers.persona_id);
                      }}
                    >
                      <Trash2 size={20} />
                      Delete
                    </MenuItem>
                    <MenuItem
                      className="!text-orange-100 !m-1 !p-2 hover:!bg-neutral-700 !rounded-lg"
                      onClick={() => {
                        handleMakeDefault(pers.persona_id);
                      }}
                    >
                      <Check size={20} />
                      Make default
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </ListItemButton>
            ))}
        </List>{" "}
        <Snackbar
          open={alertInfo.open}
          autoHideDuration={3000}
          onClose={closeAlert}
        >
          <Alert sx={{ width: "100%" }}>{alertInfo.message} </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default Personas;
