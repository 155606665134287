import { useState, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./ProfilePage.css";
import CreatedCharacters from "./CreatedCharacters";
import { useParams } from "react-router-dom";
import SavedChats from "./SavedChats";
import SavedBotcasts from "./SavedBotcasts";
import Personas from "./Personas";
import { PlatformContext } from "../../contexts/PlatformContext";
import { CircularProgress } from "@mui/material";
import { ChevronLeft, Pencil, Check, X } from "lucide-react";
import {
  Avatar,
  Button,
  ButtonGroup,
  Modal,
  ModalDialog,
  ModalClose,
  Snackbar,
  Alert,
} from "@mui/joy";
import { Cog } from "lucide-react";
import SettingsDialog from "./SettingsDialog";
import { uploadImage } from "../../apis/request";
import useRequest from "../../apis/useRequest";

type Tab = "characters" | "chats" | "botcasts" | "personas";
function useOtherProfileCheck(user: any, isAuthenticated: any) {
  const { username } = useParams();

  return useMemo(() => {
    if (user && isAuthenticated) {
      return user["https://chat.4wall.ai/username"] !== username;
    }
    return false;
  }, [user, isAuthenticated, username]);
}

function ProfilePage() {
  const { user, isLoading, isAuthenticated } = useAuth0();
  const isOther = useOtherProfileCheck(user, isAuthenticated);
  const [activeTab, setActiveTab] = useState<Tab>("characters");
  const [open, setOpen] = useState(false);
  const [openModerator, setOpenModerator] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [modMessage, setModMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [confirmMode, setConfirmMode] = useState(false);
  const makeRequest = useRequest();

  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { username } = useParams();
  const { alertInfo, setAlertInfo, showAlert, closeAlert } = context;
  if (isLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (!isAuthenticated) {
    window.location.href = "/";
    return null;
  }

  const handleImageUpload = async (file: File) => {
    if (!file.type.match(/^image\/(jpeg|jpg|png)$/i)) {
      showAlert("Please upload a JPG, JPEG or PNG image file.", "danger");
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append("image", file);
    formData.append("asset", "user");
    formData.append("identifier_id", username ? username : "");
    const response = await makeRequest<any, any>(uploadImage(), formData);
    if (!response.is_safe) {
      setModMessage(response.reasons);
      setOpenModerator(true);
    } else {
      showAlert("Image uploaded successfully", "success");
    }
    setUploading(false);
  };

  const renderTabContent = (isOther: boolean) => {
    switch (activeTab) {
      case "characters":
        return (
          <CreatedCharacters
            isOther={isOther}
            otherUsername={username ? username : ""}
          />
        );
      case "chats":
        return <SavedChats />;
      case "botcasts":
        return <SavedBotcasts />;
      case "personas":
        return <Personas />;
      default:
        return null;
    }
  };
  // redeploying
  return (
    <>
      <Modal open={openModerator} onClose={() => setOpenModerator(false)}>
        <ModalDialog className="!bg-neutral-900 !text-white !border-none">
          <ModalClose />
          <div className="flex flex-col space-y-2 items-center">
            <h2 className="text-2xl font-bold font-main text-fourwall-orange">
              Sorry, please choose a different image
            </h2>
            <p className="font-main">{modMessage}</p>
          </div>
        </ModalDialog>
      </Modal>

      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row items-center justify-center font-main space-x-10 m-10">
          <ChevronLeft
            className="text-white ml-4  hover:text-fourwall-orange cursor-pointer"
            onClick={() => window.history.back()}
            size={30}
          />

          {!isOther ? (
            uploading ? (
              <CircularProgress />
            ) : (
              <div
                className={`relative flex flex-col ${confirmMode ? " border-fourwall-orange p-2 m-2" : ""}`}
              >
                <Avatar
                  src={
                    confirmMode && selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : `${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg?${Date.now()}`
                  }
                  alt={user?.name}
                  className="!w-40 !h-40"
                />

                {!confirmMode ? (
                  <label
                    htmlFor="profile-image-upload"
                    className="absolute bottom-1 right-1 p-2 text-white bg-gray-800/90 rounded-xl cursor-pointer hover:bg-fourwall-orange transition-all duration-100"
                  >
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          if (file.size <= 5 * 1024 * 1024) {
                            // 5MB in bytes
                            setSelectedFile(file);
                            setConfirmMode(true);
                          } else {
                            // File is too large, don't show alert
                            showAlert(
                              "File size exceeds the limit of 5MB",
                              "danger"
                            );
                            e.target.value = ""; // Clear the file input
                          }
                        }
                      }}
                    />

                    <Pencil size={22} />
                  </label>
                ) : (
                  <div className="space-x-2 rounded-full mt-1 flex items-center justify-center  bg-opacity-50">
                    <Button
                      color="success"
                      variant="soft"
                      onClick={() => {
                        if (selectedFile) {
                          handleImageUpload(selectedFile);
                        }
                        setConfirmMode(false);
                      }}
                    >
                      <Check size={22} />
                    </Button>
                    <Button
                      variant="soft"
                      color="danger"
                      onClick={() => {
                        setSelectedFile(null);
                        setConfirmMode(false);
                      }}
                    >
                      <X size={22} />
                    </Button>
                  </div>
                )}
              </div>
            )
          ) : (
            <Avatar
              src={`${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg?${Date.now()}`}
              alt={username}
              className="!w-40 !h-40"
            />
          )}

          <div className="flex flex-col space-y-2 sm:items-center md:items-start">
            <h2 className="text-2xl font-bold text-white m-0">
              @
              {isOther
                ? username
                : user
                  ? user["https://chat.4wall.ai/username"]
                  : "@bhupendrajogi"}
            </h2>

            {!isOther && (
              <>
                <h3 className="text-white">
                  {user
                    ? user["https://chat.4wall.ai/first_name"]
                    : "Bhupendra"}{" "}
                  {user ? user["https://chat.4wall.ai/last_name"] : "Jogi"}
                </h3>
                <div className="profile-subinfo">
                  {user ? user.email : <></>}
                </div>

                <Cog
                  size={22}
                  className="space-x-2 rounded-full  p-1 text-white !font-bold  !border-none  !absolute !right-2 !top-0 hover:!bg-orange-500 mx-2 !my-3"
                  onClick={() => setOpen(true)}
                />
              </>
            )}
          </div>
        </div>
        <div className="tabs">
          {["characters", "chats", "botcasts", "personas"].map((tab) =>
            !isOther || tab === "characters" ? (
              <button
                key={tab}
                className={activeTab === tab ? "active-tab" : ""}
                onClick={() => setActiveTab(tab as Tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ) : null
          )}
        </div>
        <div className="p-1 mx-auto md:min-w-[40%] min-w-[80%]">
          {renderTabContent(isOther)}
        </div>

        <SettingsDialog open={open} onClose={() => setOpen(false)} />
      </div>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert sx={{ width: "100%" }}>{alertInfo.message}</Alert>
      </Snackbar>
    </>
  );
}

export default ProfilePage;
