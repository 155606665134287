import React from "react";
import clsx from "clsx";
import { useMutation, useQuery } from "convex/react";
import { KeyboardEvent, useRef, useState } from "react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { useSendInput } from "../../hooks/sendInput";
import { Player } from "../../convex/aiTown/player";
import { Conversation } from "../../convex/aiTown/conversation";
// import { Level } from "../../convex/aiTown/level";

export function MessageInput({
  worldId,
  engineId,
  humanPlayer,
  conversation,
}: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  humanPlayer: Player;
  conversation: Conversation;
}) {
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const humanName = descriptions?.playerDescriptions.find(
    (p: any) => p.playerId === humanPlayer.id
  )?.name;
  const inputRef = useRef<HTMLParagraphElement>(null);
  const inflightUuid = useRef<string | undefined>();
  const writeMessage = useMutation(api.messages.writeMessage);
  const startTyping = useSendInput(engineId, "startTyping");
  const currentlyTyping = conversation.isTyping;

  function generateUUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        parseInt(c) ^
        (crypto.getRandomValues(new Uint8Array(1))[0] &
          (15 >> (parseInt(c) / 4)))
      ).toString(16)
    );
  }
  const onKeyDown = async (e: KeyboardEvent) => {
    e.stopPropagation();

    // Set the typing indicator if we're not submitting.
    if (e.key !== "Enter") {
      console.log(inflightUuid.current);
      if (currentlyTyping || inflightUuid.current !== undefined) {
        return;
      }
      inflightUuid.current = generateUUID();
      try {
        // Don't show a toast on error.
        await startTyping({
          playerId: humanPlayer.id,
          conversationId: conversation.id,
          messageUuid: inflightUuid.current,
        });
      } finally {
        inflightUuid.current = undefined;
      }
      return;
    }

    // Send the current message.
    e.preventDefault();
    if (!inputRef.current) {
      return;
    }
    const text = inputRef.current.innerText;
    inputRef.current.innerText = "";
    if (!text) {
      return;
    }
    let messageUuid = inflightUuid.current;
    if (currentlyTyping && currentlyTyping.playerId === humanPlayer.id) {
      messageUuid = currentlyTyping.messageUuid;
    }
    messageUuid = messageUuid || generateUUID();
    await writeMessage({
      worldId,
      playerId: humanPlayer.id,
      conversationId: conversation.id,
      text,
      messageUuid,
    });
  };
  return (
    <div className="leading-tight mb-6">
      <div className="flex gap-1 mb-2 text-white" style={styles.messageMeta}>
        <span className="uppercase flex-grow">{humanName}</span>
      </div>
      <div className={clsx("bubble", "bubble-mine")}>
        <p
          className="bg-white  rounded-lg -m-1 p-1"
          ref={inputRef}
          contentEditable
          style={{ outline: "none" }}
          tabIndex={0}
          placeholder="Type here"
          onKeyDown={(e) => onKeyDown(e)}
        />
      </div>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  joinLeave: {
    fontFamily: "var(--font_b)",
    fontStyle: "italic",
    fontSize: "0.7rem",
    textAlign: "center",
    margin: "auto",
    color: "white",
  },
  messageMeta: {
    fontFamily: "var(--font_b)",
    fontSize: "0.7rem",
  },
  messageText: {
    fontFamily: "var(--font_chat)",
    fontSize: "0.8rem",
  },
};
