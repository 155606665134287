import React from "react";
// import Button from "./Button"
import Button from "@mui/joy/Button";
import { toast } from "react-toastify";
// import interactImg from "../../../assets/interact.svg";
import { useConvex, useMutation, useQuery } from "convex/react";
import { api } from "../../../convex/_generated/api";
// import { SignInButton } from '@clerk/clerk-react';
import { ConvexError } from "convex/values";
import { Id } from "../../../convex/_generated/dataModel";
import { useCallback } from "react";
import { waitForInput } from "../../../../src/hooks/sendInput";
import { useServerGame } from "../../../../src/hooks/serverGame";

export default function InteractButton({ worldStatus }: any) {
  // const { isAuthenticated } = useConvexAuth();
  // const worldStatus = useQuery(api.world.defaultWorldStatus);
  const worldId = worldStatus?.worldId;
  const game = useServerGame(worldId);
  const humanTokenIdentifier = useQuery(
    api.world.userStatus,
    worldId ? { worldId } : "skip"
  );
  const userPlayerId =
    game &&
    [...game.world.players.values()].find(
      (p) => p.human === humanTokenIdentifier
    )?.id;
  const join = useMutation(api.world.joinWorld);
  const leave = useMutation(api.world.leaveWorld);
  const isPlaying = !!userPlayerId;

  const convex = useConvex();
  const joinInput = useCallback(
    async (worldId: Id<"worlds">) => {
      let inputId;
      try {
        inputId = await join({ worldId });
      } catch (e: any) {
        if (e instanceof ConvexError) {
          toast.error(e.data);
          return;
        }
        throw e;
      }
      try {
        await waitForInput(convex, inputId);
      } catch (e: any) {
        toast.error(e.message);
      }
    },
    [convex]
  );

  const joinOrLeaveGame = () => {
    if (
      !worldId ||
      // || !isAuthenticated
      game === undefined
    ) {
      return;
    }
    if (isPlaying) {
      console.log(`Leaving game for player ${userPlayerId}`);
      void leave({ worldId });
    } else {
      console.log(`Joining game`);
      void joinInput(worldId);
    }
  };
  // if (!isAuthenticated || game === undefined) {
  //   return (
  //     <SignInButton>
  //       <button className="button text-white shadow-solid text-2xl pointer-events-auto">
  //         <div className="inline-block bg-clay-700">
  //           <span>
  //             <div className="inline-flex h-full items-center gap-4">
  //               <img className="w-4 h-4 sm:w-[30px] sm:h-[30px]" src={interactImg} />
  //               Interact
  //             </div>
  //           </span>
  //         </div>
  //       </button>
  //     </SignInButton>
  //   );
  // }
  return (
    <Button
      onClick={joinOrLeaveGame}
      style={styles.button}
      color="success"
      startDecorator={
        <img
          src="/town-assets/interact.svg"
          alt="interact"
          style={styles.svg}
        />
      }
    >
      {isPlaying ? "Leave" : "Interact"}
    </Button>
  );
}
const styles = {
  button: {
    // backgroundColor: "var(--orange-brand-accent)",
    fontFamily: "var(--font_b)",
    color: "white",
    borderRadius: "10px",
    margin: "0 10px",
  },
  svg: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
};
