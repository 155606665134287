import React from "react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { SelectElement } from "./Player";
import { Messages } from "./Messages";
import { toastOnError } from "../../toasts";
import { useSendInput } from "../../hooks/sendInput";
import { Player } from "../../convex/aiTown/player";
import { GameId } from "../../convex/aiTown/ids";
import { CircleX } from "lucide-react";

import { ServerGame } from "../../hooks/serverGame";
import { Margin } from "@mui/icons-material";

export default function PlayerDetails({
  worldId,
  engineId,
  game,
  playerId,
  setSelectedElement,
  scrollViewRef,
}: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  playerId?: GameId<"players">;
  setSelectedElement: SelectElement;
  scrollViewRef: React.RefObject<HTMLDivElement>;
}) {
  const humanTokenIdentifier = useQuery(api.world.userStatus, { worldId });

  const players = [...game.world.players.values()];
  const humanPlayer = players.find((p) => p.human === humanTokenIdentifier);
  const humanConversation = humanPlayer
    ? game.world.playerConversation(humanPlayer)
    : undefined;
  // Always select the other player if we're in a conversation with them.
  if (humanPlayer && humanConversation) {
    const otherPlayerIds = [...humanConversation.participants.keys()].filter(
      (p) => p !== humanPlayer.id
    );
    playerId = otherPlayerIds[0];
  }

  const player = playerId && game.world.players.get(playerId);
  const playerConversation = player && game.world.playerConversation(player);

  const agents = game.world.agents;
  const agent = Array.from(agents.values()).find(
    (a) => a.playerId === playerId
  );

  const plan = agent && game.agentDescriptions.get(agent.id)?.plan;

  const previousConversation = useQuery(
    api.world.previousConversation,
    playerId ? { worldId, playerId } : "skip"
  );
  //recommit arteafact
  const playerDescription = playerId && game.playerDescriptions.get(playerId);
  console.log("playerDescription", playerDescription);
  console.log("players", game.world.players);
  const startConversation = useSendInput(engineId, "startConversation");
  const acceptInvite = useSendInput(engineId, "acceptInvite");
  const rejectInvite = useSendInput(engineId, "rejectInvite");
  const leaveConversation = useSendInput(engineId, "leaveConversation");

  if (!playerId) {
    return (
      <div
        className="h-full text-xl flex text-center items-center p-4"
        style={{
          color: "var(--primary-text-color)",
          fontFamily: "var(--font_b)",
          fontSize: "1rem",
        }}
      >
        Click on an a character on the map to see chat history.
      </div>
    );
  }
  if (!player) {
    return null;
  }
  const isMe = humanPlayer && player.id === humanPlayer.id;
  const canInvite =
    !isMe && !playerConversation && humanPlayer && !humanConversation;
  const sameConversation =
    !isMe &&
    humanPlayer &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id;

  const humanStatus =
    humanPlayer &&
    humanConversation &&
    humanConversation.participants.get(humanPlayer.id)?.status;
  const playerStatus =
    playerConversation && playerConversation.participants.get(playerId)?.status;

  const haveInvite = sameConversation && humanStatus?.kind === "invited";
  const waitingForAccept =
    sameConversation &&
    playerConversation.participants.get(playerId)?.status.kind === "invited";
  const waitingForNearby =
    sameConversation &&
    playerStatus?.kind === "walkingOver" &&
    humanStatus?.kind === "walkingOver";

  const inConversationWithMe =
    sameConversation &&
    playerStatus?.kind === "participating" &&
    humanStatus?.kind === "participating";

  const onStartConversation = async () => {
    if (!humanPlayer || !playerId) {
      return;
    }
    console.log(`Starting conversation`);
    await toastOnError(
      startConversation({ playerId: humanPlayer.id, invitee: playerId })
    );
  };
  const onAcceptInvite = async () => {
    if (!humanPlayer || !humanConversation || !playerId) {
      return;
    }
    await toastOnError(
      acceptInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  const onRejectInvite = async () => {
    if (!humanPlayer || !humanConversation) {
      return;
    }
    await toastOnError(
      rejectInvite({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  const onLeaveConversation = async () => {
    if (!humanPlayer || !inConversationWithMe || !humanConversation) {
      return;
    }
    await toastOnError(
      leaveConversation({
        playerId: humanPlayer.id,
        conversationId: humanConversation.id,
      })
    );
  };
  // console.log("DEBUGGERR", !playerConversation, playerStatus);
  // const pendingSuffix = (inputName: string) =>
  //   [...inflightInputs.values()].find((i) => i.name === inputName) ? ' opacity-50' : '';

  const pendingSuffix = (s: string) => "";
  return (
    <>
      <div className="flex flex-row  items-center">
        {/* <img
            className="rounded-full w-1/2 p-4"
            src={playerDescription?.image}
            alt={playerDescription?.name}
          /> */}
        <img
          style={styles.charImg}
          src={playerDescription?.image}
          alt={playerDescription?.name}
        />
        {/* <h2 className="p-2 mt-20 font-display text-2xl sm:text-2xl text-white tracking-wider shadow-solid text-center rounded-lg"> */}
        <h3
          style={{
            textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            fontFamily: "var(--font_b)",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {playerDescription?.name}
          <p
            style={{
              fontSize: "0.8rem",
              color: "var(--primary-text-color)",
              fontFamily: "var(--font_a)",
              wordWrap: "break-word",
              width: "80%",
            }}
          >
            {playerDescription?.bio}
          </p>
        </h3>
        <a
          className="button text-white shadow-solid text-2xl cursor-pointer pointer-events-auto"
          style={{ height: "5rem" }}
          onClick={() => setSelectedElement(undefined)}
        >
          <CircleX
            size={32}
            className="m-1"
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              color: "var(--orange-brand-accent)",
            }}
          />
        </a>
      </div>

      {plan && (
        <details>
          <summary
            style={{
              fontFamily: "var(--font_b)",
              fontSize: "0.9rem",
              margin: "1rem",
              color: "var(--primary-text-color)",
              cursor: "pointer",
            }}
          >
            Plan
          </summary>
          <p
            style={{
              fontFamily: "var(--font_b)",
              fontSize: "0.8rem",
              margin: "1rem",
              color: "var(--primary-text-color)",
              backgroundColor: "gray",
              padding: "0.5rem",
              borderRadius: "0.5rem",
            }}
          >
            {plan}
          </p>
        </details>
      )}

      {canInvite && (
        <a
          className={
            "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
            pendingSuffix("startConversation")
          }
          onClick={onStartConversation}
        >
          <div className="bg-clay-700 text-center mx-4 rounded-lg">
            <span>Start conversation</span>
          </div>
        </a>
      )}
      {waitingForAccept && (
        <a className="mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto opacity-50">
          <div className="mx-4 bg-clay-700 text-center rounded-lg">
            <span>Waiting for accept...</span>
          </div>
        </a>
      )}
      {waitingForNearby && (
        <a className="mt-6  text-white shadow-solid text-xl cursor-pointer pointer-events-auto opacity-70">
          <div className="mx-10 bg-clay-700 text-center rounded-lg">
            <span>Walking over...</span>
          </div>
        </a>
      )}
      {inConversationWithMe && (
        <a
          className={
            "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
            pendingSuffix("leaveConversation")
          }
          onClick={onLeaveConversation}
        >
          <div className="bg-clay-700 text-center mx-4 rounded-lg">
            <span>Leave conversation</span>
          </div>
        </a>
      )}
      {haveInvite && (
        <>
          <a
            className={
              "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto"
            }
            onClick={onAcceptInvite}
          >
            <div className="bg-clay-500 mx-6 text-center rounded-lg">
              <span>Accept</span>
            </div>
          </a>
          <a
            className={
              "mt-6 text-white shadow-solid text-xl cursor-pointer pointer-events-auto" +
              pendingSuffix("rejectInvite")
            }
            onClick={onRejectInvite}
          >
            <div className="bg-clay-700 mx-6 text-center rounded-lg">
              <span>Reject</span>
            </div>
          </a>
        </>
      )}
      {!playerConversation &&
        player.activity &&
        player.activity.until > Date.now() && (
          <div className="box flex-grow mt-6 bg-brown-900">
            <h2
              className=" text-base sm:text-lg text-center"
              style={{
                fontFamily: "var(--font_b)",
                fontSize: "0.8rem",
                textAlign: "center",
                fontStyle: "italic",
                color: "var(--primary-text-color)",
              }}
            >
              {player.activity.description}
            </h2>
          </div>
        )}
      <div className="desc">
        <p className="leading-tight -m-4 bg-transparent text-white sm:text-sm p-2">
          {/* {!isMe && playerDescription?.description} */}
          {isMe && <i>This is you!</i>}

          {!isMe && inConversationWithMe && (
            <>
              {/* <br /> */}
              <br />
              <i>Conversing with you!</i>
            </>
          )}
        </p>
      </div>
      {!playerConversation && previousConversation && (
        <h2
          className="bg-transparent text-lg text-white rounded-lg"
          style={{
            fontFamily: "var(--font_b)",
            fontSize: "1rem",
            textAlign: "center",
            margin: "auto",
            color: "var(--orange-brand-accent)",
          }}
        >
          Previous conversation
        </h2>
      )}
      <div className="chat-messages-section">
        {!isMe &&
          playerConversation &&
          playerStatus?.kind === "participating" && (
            <Messages
              worldId={worldId}
              engineId={engineId}
              inConversationWithMe={inConversationWithMe ?? false}
              conversation={{ kind: "active", doc: playerConversation }}
              humanPlayer={humanPlayer}
              scrollViewRef={scrollViewRef}
            />
          )}
        {!playerConversation && previousConversation && (
          <>
            {/* <h2
              className="bg-transparent text-lg text-white rounded-lg"
              style={{
                fontFamily: "var(--font_b)",
                fontSize: "1rem",
                textAlign: "center",
                margin: "auto",
                color: "var(--orange-brand-accent)",
              }}
            >
              Previous conversation
            </h2> */}

            <Messages
              worldId={worldId}
              engineId={engineId}
              inConversationWithMe={false}
              conversation={{ kind: "archived", doc: previousConversation }}
              humanPlayer={humanPlayer}
              scrollViewRef={scrollViewRef}
            />
          </>
        )}
      </div>
    </>
  );
}

const styles = {
  charImg: {
    width: "5em",
    height: "5rem",
    borderRadius: "50%",
    margin: "1rem",
  },
};
