import React, { useState, useContext, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import { SocialIcon } from "react-social-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { PlatformContext } from "../../contexts/PlatformContext";
import SearchOverlay from "../Misc/SearchOverlay";
import Chip from "@mui/joy/Chip";
import Login from "../Auth/Login";
import SignupWall from "../Misc/SignupWall";
import { Chat } from "../../types";
import CSSTransition from "react-transition-group/CSSTransition";
import { UserPlus, CircleUserRound, Menu } from "lucide-react";
import MultiChatPopup from "../Misc/MultiChatPopup";
import { ChevronsLeft } from "lucide-react";
import { Home } from "lucide-react";
export interface CharacterGroup {
  character: Chat;
  chats: Chat[];
}

const Sidebar: React.FC = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [showSignupWall, setShowSignupWall] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMultiChatPopup, setShowMultiChatPopup] = useState<boolean>(false);
  const [selectedCharacterMultiChats, setSelectedCharacterMultiChats] =
    useState<Chat[]>([]);
  const [continueCharacters, setContinueCharacters] = useState<
    CharacterGroup[]
  >([]);
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const {
    setShowChats,
    userAssets,
    showChats,
    isMobile,
    socialUrls,
    isAlphaTester,
  } = context;
  const [isOpen, setIsOpen] = useState<boolean>(isMobile ? showChats : true);

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    setIsOpen(showChats);
  }, [showChats]);

  useEffect(() => {
    if (window.location.pathname === "/" && !isMobile) {
      setIsOpen(true);
      setShowChats(true);
    }
    if (
      window.location.pathname.includes("chat/") ||
      window.location.pathname.includes("town/")
    ) {
      setIsOpen(false);
      setShowChats(false);
    }
    if (
      isMobile &&
      (window.location.pathname.includes("profile/") ||
        window.location.pathname === "/")
    ) {
      setIsOpen(false);
      setShowChats(false);
    }
  }, [window.location.pathname]);

  const aggregateChats = (chats: Chat[]) => {
    const groups: { [characterId: string]: CharacterGroup } = {};

    chats.forEach((chat) => {
      if (!groups[chat.character_id]) {
        groups[chat.character_id] = { character: chat, chats: [] };
      } else {
        groups[chat.character_id].character = chat;
      }
      groups[chat.character_id].chats.push(chat);
    });

    Object.values(groups).forEach((group) =>
      group.chats.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      )
    );

    const sortedChatGroups = Object.values(groups).sort((a, b) => {
      // Convert last_interaction to a Date object and compare
      const dateA = new Date(a.character.last_interaction);
      const dateB = new Date(b.character.last_interaction);
      return dateB.getTime() - dateA.getTime(); // Sort in descending order
    });
    setContinueCharacters(Object.values(sortedChatGroups));
  };

  const [innerVisible, setInnerVisible] = useState(false);

  useEffect(() => {
    // When the sidebar opens, wait a bit before showing inner components
    if (isOpen) {
      const timer = setTimeout(() => {
        setInnerVisible(true);
      }, 200); // adjust timing based on your needs
      return () => clearTimeout(timer);
    } else {
      setInnerVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      aggregateChats(userAssets.chats);
    }
  }, []);

  const handleContinueCharacterSelect = (group: CharacterGroup): void => {
    if (group.chats.length === 1) {
      // If there's only one chat, navigate directly to that chat
      navigate(
        `/chat/${group.chats[0].character_id}/${group.chats[0].chat_id}`
      );
    } else {
      // If there are multiple chats, open a pop-up
      setShowMultiChatPopup(true);
      setSelectedCharacterMultiChats(group.chats);
    }
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (isMobile) {
      setShowChats(!isOpen);
    }
  };

  const handleClickRequiringSignUp = (route: string) => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      setShowSignupWall(true);
    }
  };

  const handleProfileClick = () => {
    if (user && user["https://chat.4wall.ai/user_id"]) {
      handleClickRequiringSignUp(
        `/profile/${user["https://chat.4wall.ai/username"]}/${user["https://chat.4wall.ai/user_id"]}`
      );
    } else {
      alert("Please sign in");
    }
  };
  return (
    <>
      {!innerVisible &&
        (isMobile ? (
          !window.location.pathname.includes("chat/") && (
            <Menu
              onClick={toggleSidebar}
              className="p-1 z-50 cursor-pointer flex-end absolute top-0 left-0 mx-2 my-3 text-white hover:bg-orange-500 rounded-full"
              size={22}
            />
          )
        ) : (
          <Menu
            onClick={toggleSidebar}
            size={22}
            className="p-1 z-50 cursor-pointer flex-end absolute top-0 left-0 text-white mx-2 my-3 hover:bg-orange-500 rounded-full"
          />
        ))}
      <Box
        sx={{
          width: isMobile
            ? isOpen || showChats
              ? "30rem"
              : "0rem"
            : isOpen
              ? "18rem"
              : window.location.pathname.includes("chat")
                ? "0rem"
                : "0rem",
          height: "100svh",
          color: "white",
          display: "flex",
          flex: "1 1 auto",
          boxSizing: "border-box",
          flexDirection: "column",
          borderRight: isOpen ? "1px solid rgba(255, 255, 255, 0.2)" : "none",
          bgcolor: isOpen ? "rgba(0,0,0,0.7)" : "transparent", // Transparent with opacity
          transition: "width 0.3s ease", // Smooth transition for width change
        }}
      >
        {showSignupWall && <SignupWall setShowSignupWall={setShowSignupWall} />}
        <Box sx={{ display: "flex" }}>
          {innerVisible && (
            <div className="w-full flex  justify-between items-center ">
              <div className="flex items-center gap-0 m-2">
                <img
                  src="/4Wall_Logo_Package/fourwall-orange-transparent-cropped.png"
                  alt="logo"
                  className="w-auto h-14 hover:opacity-80 transition-all duration-300"
                  style={{ objectFit: "contain" }}
                  onClick={() => navigate("/")}
                />
                {/* <h1
                  style={{
                    fontFamily: "var(--font_b_extended)",
                    fontSize: "1.4rem",
                    textShadow: "2px 2px 4px #000000",
                    marginLeft: "0",
                  }}
                >
                  4Wall AI
                </h1> */}
              </div>

              {/* <div className="flex-grow flex justify-end"> */}
              <div className="flex justify-end mx-2 gap-2">
                {/* {window.location.pathname !== "/" && (
                  <Home
                    className="cursor-pointer"
                    size={26}
                    onClick={() => navigate("/")}
                  />
                )} */}
                <ChevronsLeft
                  className="cursor-pointer"
                  size={28}
                  onClick={toggleSidebar}
                />
              </div>
            </div>
          )}
        </Box>

        {showSearch && (
          <SearchOverlay
            isOpen={showSearch}
            onClose={() => setShowSearch(false)}
          />
        )}
        {innerVisible && (
          <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Divider sx={styles.divider} />
            {/* {!isMobile && ( */}
            <List>
              <ListItem
                button
                onClick={() => handleClickRequiringSignUp("/create")}
              >
                <ListItemIcon sx={styles.icon}>
                  <UserPlus />
                </ListItemIcon>
                <ListItemText
                  primary="Create a character"
                  primaryTypographyProps={styles.sidebarText}
                />
              </ListItem>

              {/* {isAlphaTester && ( */}
              <ListItem>
                <Button
                  className="fancy-btn town"
                  variant="contained"
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect({
                        appState: { returnTo: "/town" },
                      });
                      return;
                    }
                    navigate("/town");
                  }}
                >
                  SPOT
                  <Chip
                    sx={{
                      margin: "0 0.5rem",
                      fontFamily: "var(--font_b)",
                      fontSize: "0.6rem",
                    }}
                    color="warning"
                    size="sm"
                    variant="outlined"
                  >
                    ALPHA
                  </Chip>
                </Button>
              </ListItem>
              <ListItem button>
                <Button
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect({
                        appState: { returnTo: "/botcast/create" },
                      });
                      return;
                    }
                    navigate(`/botcast/create`, {
                      state: { characters: [] },
                    });
                  }}
                  variant="contained"
                  className="fancy-btn"
                >
                  BOTCASTS
                </Button>
              </ListItem>
            </List>
            {/* )} */}

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <SocialIcon
                url={socialUrls.Reddit}
                target="_blank"
                style={styles.socialIconStyle}
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
              />
              <SocialIcon
                url={socialUrls.Discord}
                target="_blank"
                network="discord"
                style={styles.socialIconStyle}
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
              />
              <SocialIcon
                url={socialUrls.X}
                className="social-icon"
                target="_blank"
                fgColor="var(--orange-brand-accent)"
                bgColor="transparent"
                style={styles.socialIconStyle}
              />
            </Box>
            {isAuthenticated && (
              <>
                <Divider sx={styles.divider} />
                <p className="ml-4 font-main font-bold mb-0">CHATS</p>
              </>
            )}

            <Box
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:active, &::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "var(--orange-brand-accent)", // replace with your desired color
                  },
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "var(--orange-brand-accent)",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "var(--orange-brand-accent)",
                },
                "&::-webkit-scrollbar-thumb:active": {
                  backgroundColor: "var(--orange-brand-accent)",
                },
                "&::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-resizer": {
                  backgroundColor: "transparent",
                },
                maxHeight: isMobile
                  ? "calc(100svh - 400px)"
                  : "calc(100svh - 390px)",
              }}
            >
              <List>
                {continueCharacters.map((group) => (
                  <ListItem
                    button
                    key={group.character.character_id}
                    onClick={() => handleContinueCharacterSelect(group)}
                  >
                    <ListItemIcon>
                      <Avatar
                        alt={group.character.name}
                        src={`${process.env.REACT_APP_ASSETS_BUCKET}${group.character.character_id}/display_picture.jpg`}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={group.character.name}
                      primaryTypographyProps={styles.sidebarText}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* {!isMobile && ( */}
            <Box sx={{ mt: "auto" }}>
              <Divider sx={styles.divider} />
              <List>
                {!isAuthenticated ? (
                  <ListItem button>
                    <Login redirectUrl="/" />{" "}
                  </ListItem>
                ) : (
                  <List>
                    <ListItem button onClick={handleProfileClick}>
                      <ListItemIcon sx={styles.icon}>
                        <CircleUserRound />
                      </ListItemIcon>
                      <ListItemText
                        sx={styles.text}
                        primary="Profile"
                        primaryTypographyProps={styles.sidebarText}
                      />
                    </ListItem>
                  </List>
                )}
              </List>
            </Box>
            {/* )} */}
          </Box>
        )}
        <CSSTransition
          in={showMultiChatPopup !== null}
          timeout={300}
          classNames="popup"
          unmountOnExit
        >
          {showMultiChatPopup ? (
            <MultiChatPopup
              open={showMultiChatPopup}
              chats={selectedCharacterMultiChats}
              onClose={() => setShowMultiChatPopup(false)}
            />
          ) : (
            <></>
          )}
        </CSSTransition>
      </Box>
    </>
  );
};

const styles = {
  logo: {
    cursor: "pointer",
    fontFamily: "var(--font_b_extended)",
    color: "var(--primary-text-color)",
    marginTop: "0.67em",
    fontSize: "1.4rem",
    textShadow: "2px 2px 4px #000000",
    "&:hover": {
      color: "var(--orange-brand-accent)",
    },
  },
  icon: {
    color: "var(--orange-brand-accent)",
  },
  divider: {
    width: "90%",
    margin: "0 auto",
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  text: {
    font: "var(--font_b_extended)",
  },
  sidebarText: {
    fontFamily: "var(--font_b)",
    fontSize: "0.9rem",
  },
  socialIconStyle: {
    height: 50,
    width: 50,
    margin: "0 1.4rem",
    bgColor: "green",
    fgColor: "blue",
  },
};
export default Sidebar;
