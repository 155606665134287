import React, {
  useState,
  useRef,
  useEffect,
  RefObject,
  useContext,
} from "react";
import { useNavigate, NavigateFunction, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Avatar, Alert, Snackbar } from "@mui/joy";
import {} from "lucide-react";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Character, UserAssets, PersonaDataProps } from "../../types";
import { TypeAnimation } from "react-type-animation";
import "./Chat.css";
import SharePopup from "./SharePopup";
import { CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { Send } from "lucide-react";
import useWebSocket from "react-use-websocket";
import {
  isDarkColor,
  formatMessageText,
  reverseFormatMessageText,
  getUtcTimestamp,
} from "./utils";
import {
  getChatMessages,
  updateMessage,
  deleteMessage,
  getUserAssets,
  getCharacter,
  getChatMetadata,
} from "../../apis/request";
import { v4 as uuidv4 } from "uuid";
import { ChatContext } from "../../contexts/ChatContext";
import SettingsSidebar from "./SettingsSidebar";
import { Share2, Settings, ChevronLeft } from "lucide-react";

interface Message {
  sender: "user" | "char";
  text: string;
  message_id?: string;
  message_timestamp: string;
  active_persona_id?: string;
}

export interface PromptStyle {
  role: string;
  content: string;
}

function ChatMode() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>(() => {
    const cachedMessage = localStorage.getItem("message_cached_before_auth");
    return cachedMessage || "";
  });

  useEffect(() => {
    localStorage.removeItem("message_cached_before_auth");
  }, []);

  // const [isChatStart, setIsChatStart] = useState<boolean>(true);
  // const [modelContext, setModelContext] = useState<PromptStyle[]>([]);
  const [showTyping, setShowTyping] = useState<boolean>(false);
  const [chatId, setChatId] = useState<string>("");
  // const [username, setUsername] = useState<string>("N/A");
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [userIsCreator, setUserIsCreator] = useState<boolean>(false);
  const [characterLoading, setCharacterLoading] = useState<boolean>(true);
  const [editingMessageIndex, setEditingMessageIndex] = useState<number>(-1);
  const [editingMessageText, setEditingMessageText] = useState<string>("");
  const [senderBeingEdited, setSenderBeingEdited] = useState<string>("");
  const [isCharMessageDark, setIsCharMessageDark] = useState<boolean>(false);
  const [deleteOperationPerfomed, setDeleteOperationPerformed] =
    useState<boolean>(false);
  // const [userLoading, setUserLoading] = useState<boolean>(true);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [blockInput, setBlockInput] = useState<boolean>(false);
  const [websocketUrl, setWebsocketUrl] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false);
  const [textAreaHeight, setTextAreaHeight] = useState<string>("2rem");
  const isMobile = window.innerWidth <= 768; // Simple mobile detection based on screen width
  // const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [greetingEdited, setGreetingEdited] = useState<boolean>(false);
  const [greetingDeleted, setGreetingDeleted] = useState<boolean>(false);
  const [isGreetingTypingDone, setIsGreetingTypingDone] =
    useState<boolean>(false);
  const [scenario, setScenario] = useState<string>("");
  const [activeButtons, setActiveButtons] = useState<{ [key: number]: string }>(
    {}
  );
  const navigate = useNavigate();

  const updateTextareaHeight = () => {
    if (!textareaRef.current) return;
    const textarea = textareaRef.current;
    textarea.style.height = "2rem";
    const scrollHeight = textarea.scrollHeight;
    const newHeight = `${scrollHeight / 16}rem`;
    textarea.style.height = newHeight === "2.25rem" ? "2rem" : newHeight;
  };

  const [character, setCharacter] = useState<Character>({
    character_id: "",
    name: "",
    image_url: "",
    creator: "",
    bio: "",
    char_message_bg_color: "",
    user_message_bg_color: "",
    char_text_color: "",
    user_text_color: "",
    is_public: true,
    default_scenario: "",
    chat_background_color: "",
    chat_background_url: "",
    interactions: 0,
    num_saves: 0,
    created_at: "",
  });

  const { character_id, chat_id } = useParams();
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const {
    userAssets,
    setUserAssets,
    alertInfo,
    setAlertInfo,
    showAlert,
    closeAlert,
    username,
  } = context;

  const [activePersonaId, setActivePersonaId] = useState<string>(
    userAssets.personas.find(
      (persona) => persona.default && !persona.is_deleted
    )?.persona_id || ""
  );
  const [activePersona, setActivePersona] = useState<PersonaDataProps>(
    userAssets.personas.find(
      (persona) => persona.default && !persona.is_deleted
    ) || {
      name: "",
      description: "",
      persona_id: "",
      default: false,
    }
  );

  useEffect(() => {
    updateTextareaHeight();
  }, [message]);

  useEffect(() => {
    if (isAuthenticated) {
      const url = `${
        process.env.REACT_APP_4WALL_WEBSOCKET_API
      }?username=${encodeURIComponent(username)}`;
      setWebsocketUrl(url);
    } else {
      setWebsocketUrl(null);
    }
  }, [isAuthenticated, user]);

  const parseCharacterResponse = (response: any): Character => {
    return {
      character_id: response.character_id,
      name: response.name,
      image_url: response.image_url,
      creator: response.creator,
      bio: response.bio,
      char_message_bg_color: response.char_message_bg_color,
      user_message_bg_color: response.user_message_bg_color,
      char_text_color: response.char_text_color,
      user_text_color: response.user_text_color,
      is_public: response.is_public,
      chat_background_color: response.chat_background_color,
      chat_background_url: response.chat_background_url,
      interactions: response.interactions,
      default_scenario: response.default_scenario,
      num_saves: response.num_favorites,
      created_at: response.created_at,
    };
  };
  const fetchRemoteCharacterInfo = async () => {
    (async () => {
      if (username == "N/A") {
        console.log("username is N/A");
        return;
      }
      const userRequest = getCharacter(username, character_id || "");
      const response = await makeRequest<Character, void>(userRequest);
      const character_data = parseCharacterResponse(response);
      setCharacter(character_data);
      setCharacterLoading(false);
      let greeting = response.greeting || "";
      if (user) {
        const defaultPersona = userAssets.personas.find(
          (persona) => persona.default
        );

        const nameToReplaceWith =
          defaultPersona && !defaultPersona.is_deleted
            ? defaultPersona.name
            : user["https://chat.4wall.ai/username"];
        greeting = greeting.replaceAll("{{user}}", nameToReplaceWith);
        greeting = greeting.replaceAll("{{character}}", response.name);
        greeting = greeting.replaceAll("{{char}}", response.name);
      }

      setScenario(response.default_scenario);
      if (!chat_id) {
        if (greeting !== "") {
          setMessages([
            {
              sender: "char",
              text: formatMessageText(greeting, isCharMessageDark),
              message_id: uuidv4(),
              message_timestamp: await getUtcTimestamp(),
            },
          ]);
        } else {
          setMessages([]);
        }
      }
    })();
  };

  // useEffect(() => {
  //   const pageEnterTime = Date.now();
  //   return () => {
  //     const pageLeaveTime = Date.now();
  //     const timeSpent = pageLeaveTime - pageEnterTime;
  //   };
  // }, []);

  const fetchChat = async () => {
    try {
      const response = await makeRequest<any, void>(
        getChatMessages(chat_id, username)
      );
      const formattedMessages = response?.ui_context.map(
        (message: Message) => ({
          ...message,
          text: formatMessageText(message.text, isCharMessageDark),
        })
      );
      setMessages(formattedMessages);

      const lastUserMessage = formattedMessages
        .filter((message: Message) => message.sender === "user")
        .pop();

      const lastActivePersonaId = lastUserMessage?.active_persona_id;
      if (lastActivePersonaId !== null && lastActivePersonaId !== undefined) {
        setActivePersonaId(lastActivePersonaId);
        setActivePersona(
          userAssets.personas.find(
            (persona) =>
              persona.persona_id === lastActivePersonaId && !persona.is_deleted
          ) || {
            name: "",
            description: "",
            persona_id: "",
            default: false,
          }
        );
      }

      const metadata = await makeRequest<any, void>(
        getChatMetadata(chat_id || "")
      );

      setScenario(metadata.active_scenario);
      // setModelContext(response.current_llm_context);
      setActiveButtons(response.feedback_map);
      setShowInput(true);
      setCharacterLoading(false);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      navigate("/");
    }
  };

  const endOfMessagesRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (refVariable: RefObject<HTMLDivElement>) => {
    if (refVariable.current !== null) {
      refVariable.current.scrollIntoView({
        block: "end",
        inline: "center",
        behavior: "smooth",
      });
    }
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  if (!isAuthenticated) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
      appState: { returnTo: window.location.pathname },
    });
  }

  useEffect(() => {
    fetchRemoteCharacterInfo();

    if (chat_id !== undefined) {
      fetchChat();
      setChatId(chat_id);
    } else {
      setShowInput(true);
    }
  }, [isAuthenticated, username, character_id, chat_id]);

  useEffect(() => {
    if (!deleteOperationPerfomed) {
      scrollToBottom(endOfMessagesRef);
    } else {
      setDeleteOperationPerformed(false);
    }
  }, [messages]);

  const chatApiUrl = process.env.REACT_APP_CHAT_WEBSOCKET;
  if (!chatApiUrl) {
    throw new Error("API URL not defined in environment variables");
  }
  const websocketApiUrl = process.env.REACT_APP_4WALL_WEBSOCKET_API;
  if (!websocketApiUrl) {
    throw new Error("API URL not defined in environment variables");
  }
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    websocketUrl,
    {
      onOpen: () => console.log("WebSocket Opened"),
      onClose: () => console.log("WebSocket Closed"),
      onMessage: (event) => handleWebSocketMessage(event),
      shouldReconnect: (closeEvent) => true, // Automatically reconnect on close
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  );

  const handleWebSocketMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      if (data.end) {
        if (data.error) {
          setAlertInfo({
            open: true,
            message:
              "Sorry, there was an error processing your message. Please try again.",
            severity: "danger",
          });
          setShowTyping(false);
          setBlockInput(false);
          // setIsGenerating(false);
          return;
        }
        // setIsGenerating(true);
        // setModelContext(data.context);
        setChatId(data.chat_id);
        setBlockInput(false);
        setMessages((prev) => {
          const lastMessageIndex = prev.length - 1;
          if (
            lastMessageIndex >= 0 &&
            prev[lastMessageIndex].sender === "char"
          ) {
            const lastMessage = { ...prev[lastMessageIndex] };
            lastMessage.text = lastMessage.text.trim(); // Trim the last message text

            return [...prev.slice(0, lastMessageIndex), lastMessage];
          }
          return prev;
        });

        return;
      } else if (data.edit) {
        return;
      }
      setMessages((prev) => {
        if (data.first) {
          setShowTyping(false);
          return [
            ...prev,
            {
              sender: "char",
              text: data.message,
              message_id: data.message_id,
              message_timestamp: data.message_timestamp,
            },
          ];
        } else {
          // If it's not the first message, append to the last 'char' message
          return prev.map((msg, index) =>
            index === prev.length - 1 && msg.sender === "char"
              ? {
                  ...msg,
                  text: msg.text + data.message,
                  message_id: data.message_id,
                  message_timestamp: data.message_timestamp,
                }
              : msg
          );
        }
      });
      // Format the complete message when the stream ends
      setMessages((prev) => {
        if (prev.length > 0 && prev[prev.length - 1].sender === "char") {
          const updatedPrev = [...prev];
          updatedPrev[prev.length - 1] = {
            ...prev[prev.length - 1],
            text: formatMessageText(
              prev[prev.length - 1].text,
              isCharMessageDark
            ),
          };
          return updatedPrev;
        }
        return prev;
      });
    } catch (e) {
      console.error("Error parsing JSON:", e);
    }
  };

  const handleSendMessage = async () => {
    if (!isAuthenticated) {
      localStorage.setItem("message_cached_before_auth", message);
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
        },
        appState: { returnTo: window.location.pathname },
      });
      return;
    }

    if (message === "") {
      setAlertInfo({
        open: true,
        message: "Please enter a message before sending",
        severity: "danger",
      });
      return;
    }
    setBlockInput(true);
    const messageId = uuidv4();
    const chatIndex = messages.length;
    const timestamp = await getUtcTimestamp();
    const newMessage = {
      sender: "user",
      text: message,
      message_id: messageId,
      message_timestamp: timestamp,
      active_persona_id: activePersonaId,
    };
    const updatedMessagesForApi = [...messages, newMessage];
    setMessages((prev) => [
      ...prev,
      {
        sender: "user",
        text: formatMessageText(message, isCharMessageDark),
        message_id: messageId,
        message_timestamp: timestamp,
        active_persona_id: activePersonaId,
      },
    ]);
    setShowTyping(true);
    setMessage("");

    const is_chat_start =
      messages.length === 0 ||
      (messages.length === 1 && messages[0].sender === "char");

    const messagePayload = {
      action: "sendmessage", // Specify the action to route in the backend
      input: message, // Include the message data
      is_chat_start: is_chat_start,
      ui_context: updatedMessagesForApi.slice(0, 10), // This is only to access the greeeting message id and timestamp.
      character_id: character_id,
      chat_id: chatId,
      username: username,
      active_persona_id: activePersonaId,
      active_scenario: scenario,
      chat_index: chatIndex,
      input_uuid: messageId,
      input_timestamp: timestamp,
      regen: false,
      greeting_edited: greetingEdited,
      greeting: messages[0]?.text ?? "",
      greeting_deleted: greetingDeleted,
    };
    // setIsChatStart(false);
    sendJsonMessage(messagePayload);
  };

  const handleLikes = (
    isLike: boolean,
    index: number,
    message_id: string | undefined
  ) => {
    var turn = Math.floor(index / 2);
    setActiveButtons((prev) => ({
      ...prev,
      [index]: isLike ? "like" : "dislike",
    }));
    if (messages[0].sender === "char") {
      turn--;
    }
    var apiUrl = process.env.REACT_APP_FEEDBACK_API;
    if (!apiUrl) {
      throw new Error("API URL not defined in environment variables");
    }
    var feedbackData = {
      chat_id: chatId,
      feedback: isLike,
      message_id: message_id,
      message_timestamp: messages[index].message_timestamp,
      chat_index: index,
      feedback_mode: "thumbs",
    };
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    })
      .then((response) => {
        if (!response.ok) {
          // alert("Oops somethinfg's wrong with like/dislike call"); TEMPORARY
          console.log("Oops something's wrong with like/dislike call");
        } else {
          console.log("Like/dislike successful!");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error liking/disliking:", error);
      });
  };
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const handleTextareaFocus = () => setIsKeyboardVisible(true);
  const handleTextareaBlur = () => setIsKeyboardVisible(false);
  const handleSave = () => {
    if (!isAuthenticated) {
      alert("Please log in to save your chat!");
      return;
    }
    if (user) {
      const username = user["https://chat.4wall.ai/username"];
      window.location.href = "/profile/" + username;
    } else {
      alert("wait for user load please...");
    }
  };

  const handleDelete = () => {
    const deleteChat = async () => {
      const request_body = {
        item: "chat",
        chat_id: chatId,
      };
      const apiUrl = process.env.REACT_APP_DELETE_API;
      if (!apiUrl) {
        throw new Error("API URL not defined in environment variables");
      }

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: JSON.stringify(request_body),
        });

        if (response.ok) {
          const data = await response.json();
          // setMessages([]);
          // setModelContext([]);
          setChatId("");
          setIsDeleted(true);
        } else {
          throw new Error(`Server responded with ${response.status}`);
        }
      } catch (error) {
        console.error("There was an error fetching the characters:", error);
      }
    };

    deleteChat();
  };

  useEffect(() => {
    if (isDeleted) {
      window.location.href = "/chat/" + character_id;
      setIsDeleted(false);
    }
  }, [isDeleted, character_id, navigate]);

  const userMessageStyle = {
    backgroundColor: character.user_message_bg_color,
    color: character.user_text_color,
  };
  const charMessageStyle = {
    backgroundColor: `${character.char_message_bg_color}`,
    color: character.char_text_color,
  };

  useEffect(() => {
    if (charMessageStyle.color) {
      setIsCharMessageDark(isDarkColor(charMessageStyle.color));
    }
  }, [charMessageStyle.color]);

  const chatBackgroundImageStyle = {
    backgroundImage: `
      linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
      url(${character.chat_background_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed", // This can ensure the background is fixed during scroll
  };
  const chatBackgroundColorStyle = {
    background: `linear-gradient(to top, rgba(0,0,0,0.3) 10%, transparent 40%), 
    linear-gradient(to bottom, rgba(0,0,0,0.3) 10%, transparent 40%), 
    ${
      character.chat_background_color === "#f2f2f2"
        ? "#333"
        : character.chat_background_color
    }`,
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // Focus the hidden input on component mount
    if (hiddenInputRef.current) {
      hiddenInputRef.current.focus();
    }
  }, []);

  const handleSendClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    handleSendMessage();
  };
  const handleRegenerate = () => {
    const isLastMessageFromBot =
      messages.length > 0 && messages[messages.length - 1].sender !== "user";
    const char_message_id = messages[messages.length - 1].message_id || "";
    const char_message_timestamp =
      messages[messages.length - 1].message_timestamp;
    const updatedMessages = isLastMessageFromBot
      ? messages.slice(0, -1)
      : [...messages];

    // Update the messages state to remove the bot's last message
    setMessages(updatedMessages);
    setShowTyping(true);
    (async () => {
      const userRequest = updateMessage(char_message_id, chatId, "regen");
      const requestBody = {
        message_timestamp: char_message_timestamp,
        active_persona_id: activePersonaId,
        active_scenario: scenario,
      };
      const response = await makeRequest<any, any>(userRequest, requestBody);
    })();
  };

  const handleEditMessage = (messageIndex: number, sender: string) => {
    if (messageIndex === 0 && sender === "char") {
      setGreetingEdited(true);
    }
    setEditingMessageIndex(messageIndex);
    setSenderBeingEdited(sender);
    const originalFormatText = reverseFormatMessageText(
      messages[messageIndex].text
    );
    setEditingMessageText(originalFormatText);
  };

  const handleSaveEditedMessage = (index: number) => {
    if (editingMessageIndex !== null && editingMessageText.trim() !== "") {
      const formattedText = formatMessageText(
        editingMessageText,
        isCharMessageDark
      );
      const updatedMessages = messages.map((msg, idx) =>
        idx === editingMessageIndex ? { ...msg, text: formattedText } : msg
      );
      setMessages(updatedMessages);
      setActiveButtons((prev) => ({
        ...prev,
        [index]: "",
      }));
      if (editingMessageIndex !== 0 || senderBeingEdited === "user") {
        (async () => {
          const userRequest = updateMessage(
            messages[index].message_id || "",
            chatId,
            "edit"
          );
          const requestBody = {
            message_timestamp: messages[index].message_timestamp,
            edited_message: formattedText,
          };
          const response = await makeRequest<any, any>(
            userRequest,
            requestBody
          );
        })();
      }
      setEditingMessageIndex(-1);
      setEditingMessageText("");
    }
  };

  const handleDeleteMessage = (
    index: number,
    message_id: string,
    message_timestamp: string | undefined
  ) => {
    (async () => {
      const userRequest = deleteMessage(message_id, chat_id);
      const requestBody = {
        message_timestamp: message_timestamp,
        index: index,
      };
      const response = await makeRequest<any, any>(userRequest, requestBody);

      if (response.success) {
        showAlert("Message deleted", "success");
        setMessages((prev) => prev.filter((_, i) => i !== index));
        setActiveButtons((prev) => {
          const updatedButtons = { ...prev };
          delete updatedButtons[index];
          return updatedButtons;
        });
        setDeleteOperationPerformed(true);
      } else {
        showAlert("Error deleting message", "danger");
      }
      // setModelContext(response.context);
    })();
  };

  if (isLoading || characterLoading) {
    return (
      <div className="loading-centered">
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  }
  const refetchUserAssets = async (username: string) => {
    (async () => {
      const userRequest = getUserAssets(username);
      const response = await makeRequest<UserAssets, void>(userRequest);
      setUserAssets(response);
    })();
  };

  const handleBackClick = async () => {
    try {
      if (username !== "N/A") refetchUserAssets(username);
      navigate("/"); // Navigate to home page
    } catch (error) {
      console.error("Error in refetching user assets: ", error);
    }
  };

  const handleUsernameClick = () => {
    navigate(`/profile/${encodeURIComponent(character.creator)}`);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        if (!isMobile) {
          handleSendMessage();
        }
      }
    }
  };
  if (!character_id) {
    return;
  }

  return (
    <ChatContext.Provider
      value={{
        chatId,
        setChatId,
        character_id,
        activePersonaId,
        setActivePersonaId,
        activePersona,
        setActivePersona,
        scenario,
        setScenario,
      }}
    >
      <div className="flex flex-row ">
        <div
          className={`flex flex-col w-full transition-all ease-in-out duration-300 items-center flex-1 h-screen-dynamic max-h-screen-dynamic ${!isMobile && isSidebarOpen ? "pr-80" : ""}`}
          style={
            character.chat_background_url !== ""
              ? chatBackgroundImageStyle
              : character.chat_background_color === "#f2f2f2" ||
                  character.chat_background_color === "#333"
                ? undefined
                : chatBackgroundColorStyle
          }
        >
          <div className="w-full md:w-3/4 justify-between 2xl:hidden p-6  h-16   flex font-main">
            <div className="flex gap-3 items-center md:mt-2">
              <ChevronLeft
                className="m-auto cursor-pointer text-white hover:text-orange-500"
                size={32}
                onClick={handleBackClick}
              />

              <Avatar
                className="!w-20 !h-20 md:!w-28 md:!h-28"
                src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${Date.now()}`}
                alt={character.name}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.src =
                    "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                }}
              />

              <div className="flex flex-col space-y-1 text-white m-auto">
                <h2 className="m-0">{character.name}</h2>
                <p
                  className="text-sm cursor-pointer hover:text-orange-500"
                  onClick={handleUsernameClick}
                >
                  @{character.creator}
                </p>
              </div>
            </div>

            <div className="flex gap-2 md:!mt-2" ref={dropdownRef}>
              <Share2
                className="m-4 text-white cursor-pointer hover:text-orange-500"
                style={{ cursor: "pointer" }}
                onClick={() => setSharePopupOpen(!sharePopupOpen)}
              />
              {isMobile && (
                <Settings
                  className="m-4 text-white cursor-pointer hover:!text-orange-500 z-40"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                />
              )}
            </div>
          </div>
          <div className="flex w-full md:w-3/4 flex-col justify-start h-full overflow-x-hidden align-middle scrollbar-hide transition-all duration-300 ease-in-out">
            {messages.map((message, index) => (
              <div key={index} className={`chat-message ${message.sender}`}>
                {message.sender == "char" && (
                  <Avatar
                    className="chat-message-chardp"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                      target.onerror = null; // prevents looping
                      target.src =
                        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                    }}
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/display_picture.jpg?${Date.now()}`}
                    alt={character.name}
                  />
                )}
                <div
                  className={`flex flex-col ${message.sender === "user" ? "items-end" : "items-start"}`}
                >
                  {message.sender === "user" && (
                    <p className="text-xs text-neutral-200 mx-1 my-0 font-chat ">
                      {activePersonaId !== ""
                        ? userAssets.personas.find(
                            (persona) =>
                              persona.persona_id === message.active_persona_id
                          )?.name || ""
                        : username}
                    </p>
                  )}
                  {message.sender === "char" && (
                    <p className="text-xs text-neutral-200 mx-1 my-0 font-chat">
                      {character.name}
                    </p>
                  )}
                  <div
                    className="message-content"
                    style={
                      message.sender === "user"
                        ? userMessageStyle
                        : charMessageStyle
                    }
                  >
                    {editingMessageIndex === index ? (
                      <>
                        <p style={{ fontStyle: "italic", padding: "0" }}>
                          Editing
                        </p>
                        <textarea
                          className="edit-message-input"
                          value={editingMessageText}
                          onChange={(e) =>
                            setEditingMessageText(e.target.value)
                          }
                          rows={editingMessageText.length / 45}
                          cols={100}
                          maxLength={700}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              handleSaveEditedMessage(index);
                            }
                          }}
                          onBlur={() => handleSaveEditedMessage(index)}
                        />
                      </>
                    ) : index === 0 &&
                      messages.length < 2 &&
                      !isGreetingTypingDone &&
                      message.sender === "char" ? (
                      <TypeAnimation
                        sequence={[
                          reverseFormatMessageText(message.text),
                          () => {
                            setIsGreetingTypingDone(true);
                          },
                        ]}
                        wrapper="div"
                        cursor={false}
                        speed={90}
                      />
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: message.text }} />
                    )}
                  </div>

                  {message.sender === "user" && (
                    <div className="feedback-buttons">
                      {index === messages.length - 2 && (
                        <>
                          {editingMessageIndex === -1 ? (
                            <EditIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditMessage(index, "user")}
                            ></EditIcon>
                          ) : (
                            <DoneIcon
                              fontSize="small"
                              onClick={() => handleSaveEditedMessage(index)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </>
                      )}

                      <DeleteIcon
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDeleteMessage(
                            index,
                            message.message_id || "",
                            message.message_timestamp
                          );
                        }}
                      />
                    </div>
                  )}
                  {message.sender === "char" && (
                    <div className="feedback-buttons">
                      {index === messages.length - 1 && (
                        <>
                          {editingMessageIndex === -1 ? (
                            <EditIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEditMessage(index, "char")}
                            ></EditIcon>
                          ) : (
                            <DoneIcon
                              fontSize="small"
                              onClick={() => handleSaveEditedMessage(index)}
                              style={{ cursor: "pointer" }}
                            />
                          )}

                          {!blockInput && messages.length > 1 && (
                            <RefreshIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                              onClick={handleRegenerate}
                            ></RefreshIcon>
                          )}
                        </>
                      )}

                      <ThumbUpIcon
                        fontSize="small"
                        className={`like-button ${
                          activeButtons[index] === "like" ? "active" : ""
                        }`}
                        onClick={() =>
                          handleLikes(true, index, message.message_id)
                        }
                      ></ThumbUpIcon>
                      <ThumbDownIcon
                        fontSize="small"
                        className={`dislike-button ${
                          activeButtons[index] === "dislike" ? "active" : ""
                        }`}
                        onClick={() =>
                          handleLikes(false, index, message.message_id)
                        }
                      ></ThumbDownIcon>

                      {/* {index > 0 && ( */}
                      <DeleteIcon
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (index > 0) {
                            handleDeleteMessage(
                              index,
                              message.message_id || "",
                              message.message_timestamp
                            );
                          } else {
                            setMessages((prev) =>
                              prev.filter((_, i) => i !== index)
                            );
                            setGreetingDeleted(true);
                          }
                        }}
                      />
                      {/* )} */}
                    </div>
                  )}
                </div>
                {message.sender == "user" && (
                  <Avatar
                    className="chat-message-chardp"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src =
                        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                    }}
                    src={
                      message.active_persona_id === ""
                        ? `${process.env.REACT_APP_ASSETS_BUCKET}users/${username}/display_picture.jpg`
                        : `${process.env.REACT_APP_ASSETS_BUCKET}personas/${message.active_persona_id}/display_picture.jpg`
                    }
                    alt={
                      message.active_persona_id &&
                      message.active_persona_id !== ""
                        ? userAssets.personas.find(
                            (p) => p.persona_id === message.active_persona_id
                          )?.name
                        : activePersona.name
                    }
                  />
                )}
              </div>
            ))}
            {showTyping && (
              <div className="chat-message char">
                <div className="flex flex-row items-start">
                  <Avatar
                    className="chat-message-chardp"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                      target.onerror = null; // prevents looping
                      target.src =
                        "https://4thwall-assets.s3.amazonaws.com/default_assets/display_picture.jpg";
                    }}
                    src={character.image_url}
                    alt={character.name}
                  />

                  <div
                    className="message-content flex flex-col"
                    style={charMessageStyle}
                  >
                    <p className="text-xs text-neutral-200 m-0 p-0 font-chat">
                      {character.name}
                    </p>
                    <div>
                      <img
                        className="typing"
                        src="/images/typing.gif"
                        alt="Typing..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="shadow-scroll-container"
              style={{ height: 1 + "px" }}
              ref={endOfMessagesRef}
            ></div>
          </div>

          <div className="w-full h-[120px] lg:h-[150px]"></div>
          <div className="flex w-full  flex-col max-w-2xl">
            <div className="w-full flex items-center fixed bottom-0 self-center bg-background h-fit flex-col max-w-4xl pb-4">
              <div className="md:w-full w-[90%] max-w-3xl flex justify-center items-center transition-all duration-300 ease-in-out">
                {showInput && (
                  <>
                    <input
                      ref={hiddenInputRef}
                      type="text"
                      style={{
                        position: "absolute",
                        opacity: 0,
                        height: 0,
                        fontSize: "16px", // Prevents zoom on focus in iOS
                      }}
                    />
                    <div className="flex grow items-end p-1 rounded-3xl placeholder:text-placeholder bg-surface-elevation-1 m-2 border-solid border-1 border-border-outline bg-orange-100 ">
                      <div
                        ref={inputRef}
                        className="w-full relative flex flex-col ml-2"
                      >
                        <textarea
                          className="flex px-3 items-center justify-center w-[97%] border border-0 bg-transparent text-lg font-chat disabled:cursor-not-allowed disabled:opacity-50  focus-visible:outline-none border-input text-md border-none bg-surface-elevation-1 placeholder:text-placeholder placeholder:overflow-hidden placeholder:whitespace-nowrap"
                          value={message}
                          ref={textareaRef}
                          onFocus={handleTextareaFocus}
                          onBlur={handleTextareaBlur}
                          inputMode="text"
                          onInput={updateTextareaHeight}
                          onChange={(e) => {
                            setMessage(e.target.value);
                            updateTextareaHeight();
                          }}
                          onKeyDown={handleKeyDown}
                          maxLength={700}
                          placeholder="Type your message..."
                          style={{
                            height: textAreaHeight,
                            minHeight: "2rem",
                            maxHeight: "200px",
                            // overflow: "auto",
                          }}
                        />
                      </div>
                      <div className="flex gap-3">
                        <button
                          disabled={blockInput || !message}
                          className={`z-0 inline-flex items-center justify-center border-none rounded-full p-2 text-white hover:bg-fourwall-orange ${
                            blockInput || !message
                              ? "bg-orange-300"
                              : "bg-fourwall-orange"
                          }`}
                          onClick={handleSendClick}
                        >
                          {!blockInput && <Send size={22} />}

                          {blockInput && (
                            <CircularProgress color="inherit" size={22} />
                          )}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Snackbar
            open={alertInfo.open}
            autoHideDuration={3000}
            onClose={closeAlert}
          >
            <Alert sx={{ width: "100%" }} color={alertInfo.severity}>
              {alertInfo.message}
            </Alert>
          </Snackbar>
          <SettingsSidebar
            isOpen={isSidebarOpen}
            setIsOpen={setIsSidebarOpen}
            handleSave={handleSave}
            handleDelete={handleDelete}
          />
        </div>
      </div>
      <SharePopup
        open={sharePopupOpen}
        setOpen={setSharePopupOpen}
        url={`https://${process.env.REACT_APP_ENVIRONMENT}.4wall.ai/chat/${character_id}`}
        name={character.name}
        id={character.character_id}
      />
    </ChatContext.Provider>
  );
}

const styles = {
  username: {
    margin: "auto",
    cursor: "pointer",
    transition: "transform 0.3s",
    "&:hover": { transform: "scale(1.1)" },
  },
};

export default ChatMode;
