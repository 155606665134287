import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import ChatCard from "./ChatCard";
import { Input } from "@mui/joy";
import { Search } from "lucide-react";

export interface SavedChatsProps {
  character_id: string;
  chat_id: string;
  name: string;
  last_interaction: string;
  image_url: string;
}

function SavedChats() {
  const context = useContext(PlatformContext);
  const [chats, setChats] = useState<SavedChatsProps[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { characters, setCharacters, userAssets, setUserAssets } = context;

  const fetchChats = async () => {
    if (isAuthenticated && user) {
      const sortedChats = userAssets.chats.sort(
        (a, b) =>
          new Date(b.last_interaction).getTime() -
          new Date(a.last_interaction).getTime()
      );
      setChats(sortedChats);
    }
  };

  useEffect(() => {
    fetchChats();
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const filteredChats = chats.filter((chat) => {
    return chat.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div>
      <Input
        placeholder="Search chats by character name"
        startDecorator={<Search />}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="!bg-gray-800 !border-orange-400  !z-50 !m-auto !gap-2 md:!w-1/2 !w-full !max-w-3xl !border-spacing-1 !rounded-3xl !bg-surface-elevation-1 !p-2  focus:!outline-orange-400 !text-orange-100"
      />
      <div className="flex flex-row flex-wrap justify-center ">
        {filteredChats.map((char) => (
          <ChatCard
            key={char.chat_id}
            {...char}
            setChats={setChats}
            setUserAssets={setUserAssets}
          />
        ))}
      </div>
    </div>
  );
}

export default SavedChats;
