import { ObjectType, v } from "convex/values";
import { GameId, parseGameId, playerId } from "./ids";

export const serializedPlayerDescription = {
  playerId,
  name: v.string(),
  description: v.string(),
  character: v.string(),
  image: v.string(),
  bio: v.string(),
};
export type SerializedPlayerDescription = ObjectType<
  typeof serializedPlayerDescription
>;

export class PlayerDescription {
  playerId: GameId<"players">;
  name: string;
  description: string;
  character: string;
  image: string;
  bio: string;

  constructor(serialized: SerializedPlayerDescription) {
    const { playerId, name, description, character, image, bio } = serialized;
    this.playerId = parseGameId("players", playerId);
    this.name = name;
    this.description = description;
    this.character = character;
    this.image = image;
    this.bio = bio;
  }

  serialize(): SerializedPlayerDescription {
    const { playerId, name, description, character, image, bio } = this;
    return {
      playerId,
      name,
      description,
      character,
      image,
      bio,
    };
  }
}
