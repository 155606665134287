import React, { useState } from "react";
import { useRef } from "react";
import PixiGame from "./PixiGame";
// import { useResizeObserver } from "usehooks-ts";
import useResizeObserver from "./hooks/useResizeObserver";
import { Stage } from "@pixi/react";
import { ConvexProvider, useConvex, useQuery } from "convex/react";
import PlayerDetails from "./PlayerDetails";
import { api } from "../../convex/_generated/api";
import { useWorldHeartbeat } from "../../hooks/useWorldHeartbeat";
import { useHistoricalTime } from "../../hooks/useHistoricalTime";
import { DebugTimeManager } from "./DebugTimeManager";
import { GameId } from "../../convex/aiTown/ids";
import { useServerGame } from "../../../src/hooks/serverGame";
import { ChevronLeft, CircleHelp } from "lucide-react";
import FreezeButton from "./FreezeButton";
import InteractButton from "./buttons/InteractButton";
import { useNavigate } from "react-router-dom";
import { Modal, ModalDialog } from "@mui/joy";
import GodviewPlayerDetails from "./GodviewPlayerDetails";

import { X } from "lucide-react";

// export const SHOW_DEBUG_UI = !!import.meta.env.VITE_SHOW_DEBUG_UI;
export const SHOW_DEBUG_UI = false;
export default function Game({ worldStatus, townName }: any) {
  const convex = useConvex();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [focusedPlayerId, setFocusedPlayerId] = useState<GameId<"players">>();
  const frozen =
    worldStatus?.status === "stoppedByDeveloper" ||
    worldStatus?.status === "inactive";
  const [selectedElement, setSelectedElement] = useState<{
    kind: "player";
    id: GameId<"players">;
  }>();
  // const gameWrapperRef = useRef<HTMLDivElement>(null);

  // const width = 800;
  // const height = 600;
  const [gameWrapperRef, { width, height }] = useResizeObserver();

  // const worldStatus = useQuery(api.world.defaultWorldStatus);

  const worldId = worldStatus?.worldId;
  const engineId = worldStatus?.engineId;

  const game = useServerGame(worldId);
  const players = [...(game?.world.players.values() || [])];
  // console.log(players);
  // const playerDescriptions = players.map((player) => {
  //   return {
  //     id: player.id,
  //     description: game?.playerDescriptions.get(player.id),
  //   };
  // });
  // const playerConversations = players.map((player) => {
  //   return {
  //     id: player.id,
  //     conversation: game?.world.playerConversation(player),
  //   };
  // });
  // console.log("uplayerDescriptions", playerDescriptions);
  useWorldHeartbeat();

  const worldState = useQuery(
    api.world.worldState,
    worldId ? { worldId } : "skip"
  );
  const { historicalTime, timeManager } = useHistoricalTime(worldState?.engine);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const handlePlayerFocus = (playerId: GameId<"players">) => {
    setSelectedElement({ kind: "player", id: playerId });
    setFocusedPlayerId(playerId);
  };

  if (!worldId || !engineId || !game) {
    return null;
  }

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog
          style={{
            backgroundColor: "var(--blue-brand-accent)",
            color: "white",
            fontFamily: "var(--font_b)",
            border: "none",
          }}
        >
          <X
            onClick={() => setOpen(false)}
            style={{
              position: "fixed",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
          />
          <ul>
            <h1 className="text-fourwall-orange">Welcome to 4Wall Town</h1>
            <h2 className="text-white">Here’s how it works:</h2>
            <li>
              <strong>Move the Map:</strong> Click and drag the screen to move
              the map around.
            </li>
            <li>
              <strong>Interact with Characters:</strong> Click the "Interact"
              button to add your avatar to the game. Walk around and chat with
              the town's residents.
            </li>
            <li>
              <strong>Walk Around:</strong> Click on the ground to walk your
              character around the town.
            </li>
            <li>
              <strong>Leave the Town:</strong> Click the "Leave" button to
              remove your character from the game. This puts you in spectator
              mode where you can observe the town without participating.
            </li>
            <li>
              <strong>Read Conversations:</strong> Click on any character to see
              their chat history and follow their conversations.
            </li>
            <li>
              <strong>Control the Game Speed:</strong> Use the "Freeze" button
              to pause the game if things are moving too fast, and the
              "Unfreeze" button to resume.
            </li>
            <li>
              <strong>Status Bar:</strong> The status bar on the top left of the
              screen shows what each character is doing at any given time. Click
              on a character's name in the status bar to jump to their location.
            </li>

            <h2 className="text-fourwall-orange">
              Enjoy your time exploring 4Wall Town and interacting with its
              residents!
            </h2>
          </ul>
        </ModalDialog>
      </Modal>
      <div className="absolute inset-0 flex flex-row" style={{ zIndex: 0 }}>
        {SHOW_DEBUG_UI && (
          <DebugTimeManager
            timeManager={timeManager}
            width={200}
            height={100}
          />
        )}

        <div className=" w-full grid grid-rows-[300px_1fr] lg:grid-rows-[1fr] lg:grid-cols-[1fr_auto] lg:grow  min-h-[620px]  game-frame">
          <div
            className="relative overflow-hidden bg-brown-900 rounded-lg"
            ref={gameWrapperRef}
          >
            <div className="absolute inset-0">
              <div className="container">
                <div style={styles.navigator}>
                  <div style={styles.freezeInteract}>
                    <ChevronLeft
                      onClick={() => navigate("/town")}
                      className="back-arrow"
                      style={{
                        margin: "auto",
                      }}
                    />
                    <p style={styles.title}>{townName}</p>
                    <FreezeButton worldStatus={worldStatus} />
                    <InteractButton worldStatus={worldStatus} />
                    <CircleHelp
                      color="white"
                      size={32}
                      onClick={() => setOpen(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "0.9rem",
                      fontFamily: "var(--font_a)",
                      margin: "auto 2rem",
                    }}
                  >
                    Click on a character to see their chat history
                  </p>
                </div>
                <Stage width={width} height={height}>
                  {/* <div style={styles.header}> */}

                  {/* </div> */}

                  <ConvexProvider client={convex}>
                    <PixiGame
                      game={game}
                      worldId={worldId}
                      engineId={engineId}
                      width={width}
                      height={height}
                      historicalTime={historicalTime}
                      setSelectedElement={setSelectedElement}
                      focusedPlayerId={focusedPlayerId}
                      worldStatus={worldStatus}
                    />
                  </ConvexProvider>
                </Stage>
              </div>
            </div>
          </div>
          {/* Right column area */}
        </div>
        {selectedElement?.id && (
          <div className="game-info-section" ref={scrollViewRef}>
            <PlayerDetails
              worldId={worldId}
              engineId={engineId}
              game={game}
              playerId={selectedElement?.id}
              setSelectedElement={setSelectedElement}
              scrollViewRef={scrollViewRef}
            />
          </div>
        )}
        <div style={styles.godviewSection}>
          <div style={styles.godviewSectionContent}>
            <h3 className="m-2">Characters</h3>
            {players.map((player) => (
              <GodviewPlayerDetails
                worldId={worldId}
                key={player.id}
                playerId={player.id}
                game={game}
                setSelectedElement={setSelectedElement}
                onPlayerClick={handlePlayerFocus}
              />
            ))}
          </div>

          <div style={styles.frozenMessage}>
            {frozen && <p>SPOT PAUSED. Click Unfreeze to resume.</p>}
          </div>
        </div>
      </div>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  navigator: {
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: "0.7rem",
    padding: "0.3rem",
    left: 0,

    position: "fixed",
    bottom: 10,
    margin: "auto 1rem",
    zIndex: 2000,
    alignItems: "center",
    justifyContent: "center",
  },
  freezeInteract: {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
  },
  title: {
    fontFamily: "var(--font_b)",
    fontSize: "1.5rem",
    margin: "auto 2rem",
    color: "white",
  },
  gameContainer: {
    maxWidth: "1400px",
    width: "100%",
  },
  godviewSection: {
    display: "flex",
    flexDirection: "row",

    borderRadius: "1rem",
    padding: "0.3rem",
    position: "fixed",
    top: 10,
    margin: "auto 1rem",
    zIndex: 2000,
    color: "var(--primary-text-color)",
    fontFamily: "var(--font_b)",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  godviewSectionContent: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "0.5rem",
    borderRadius: "0.4rem",
    overflow: "auto",
  },
  frozenMessage: {
    zIndex: 2000,
    height: "100%",
    margin: "0  1rem ",
    color: "white",
    fontFamily: "var(--font_b)",
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "0.5rem",
    borderRadius: "0.4rem",
    transition: "background-color 0.3s ease",
  },
};
