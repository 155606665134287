import React, { useState, useContext, useEffect } from "react";
import { CharacterState } from "./Create";
import { PlatformContext } from "../../contexts/PlatformContext";
import useRequest from "../../apis/useRequest";
import { uploadImage } from "../../apis/request";
import { Avatar, Button, Snackbar, Alert, CircularProgress } from "@mui/joy";
import { ImageUp, Check, X } from "lucide-react";

export const ImageUploadComponent: React.FC<{
  character: CharacterState;
  setCharacter: (character: CharacterState) => void;
  setImageSource: (imageSource: string) => void;
  input_id: string;
  setModMessage: (message: string) => void;
  setOpenModerator: (open: boolean) => void;

  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
}> = ({
  character,
  setCharacter,
  setImageSource,
  input_id,
  setModMessage,
  setOpenModerator,
  selectedFile,
  setSelectedFile,
}) => {
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [confirmMode, setConfirmMode] = useState(false);
  const [uploading, setUploading] = useState(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "usePlatformContext must be used within a PlatformProvider"
    );
  }
  const { username, alertInfo, setAlertInfo, showAlert, closeAlert } = context;
  const makeRequest = useRequest();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setSelectedFile(file);
        setConfirmMode(true);
        console.log("file", file);
        if (e.target.files && e.target.files.length > 0) {
          // setCharacter({ ...character, image: e.target.files[0] });
          setImageSource(URL.createObjectURL(e.target.files[0]));
        }
      } else {
        alert("File size exceeds the limit of 5MB");
        e.target.value = "";
      }
    }
  };

  const handleImageUpload = async (file: File) => {
    if (!file.type.match(/^image\/(jpeg|jpg|png)$/i)) {
      alert("Please upload a JPG, JPEG or PNG image file.");
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append("image", file);
    if (input_id === "imageInput") {
      formData.append("asset", "character");
    } else {
      formData.append("asset", "background");
    }
    formData.append(
      "identifier_id",
      character.character_id ? character.character_id : ""
    );
    const response = await makeRequest<any, any>(uploadImage(), formData);
    if (!response.is_safe) {
      setModMessage(response.reasons);
      setOpenModerator(true);
    } else {
      showAlert("Image uploaded successfully", "success");
    }
    setUploading(false);
  };

  const handleConfirm = () => {
    if (selectedFile) {
      handleImageUpload(selectedFile);
    }
    setConfirmMode(false);
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setConfirmMode(false);
  };

  useEffect(() => {
    if (selectedFile) {
      setConfirmMode(true);
      setImageSource(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile, setImageSource]);

  return (
    <>
      <div className="flex flex-col items-center md:items-start md:w-1/3 w-full">
        <div
          className={`relative md:w-60 md:h-60 w-40 h-40 ${
            confirmMode ? "border-fourwall-orange p-2 m-2" : ""
          }`}
        >
          {uploading ? (
            <CircularProgress className=" !mx-auto!text-orange-500 !border-orange-500 !border-none !outline-none" />
          ) : (
            <Avatar
              src={
                confirmMode && selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : `${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/${input_id === "imageInput" ? "display_picture.jpg" : "background_picture.jpg"}?${Date.now()}`
              }
              alt={character.name}
              className="!w-40 !h-40 md:!w-60 md:!h-60 !rounded-lg !bg-orange-200"
            />
          )}

          {!confirmMode ? (
            <div>
              <label
                htmlFor={input_id}
                className="absolute bottom-0 right-0  p-1 text-white bg-gray-800/90 rounded-lg cursor-pointer hover:bg-fourwall-orange transition-all duration-100 m-1"
              >
                <input
                  id={input_id}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <ImageUp size={22} />
              </label>
            </div>
          ) : (
            <div className="absolute bottom-0 right-0 space-x-2 rounded-full mt-1 flex items-center justify-center bg-opacity-50">
              <Button color="success" variant="soft" onClick={handleConfirm}>
                <Check size={22} />
              </Button>
              <Button variant="soft" color="danger" onClick={handleCancel}>
                <X size={22} />
              </Button>
            </div>
          )}
        </div>
        <p className="text-whie text-xs  md:ml-2 text-center md:text-left md:w-2/3 w-full">
          {input_id === "imageInput"
            ? "Please upload your character's display image or gif. Only JPG, JPEG, PNG, GIF formats are accepted. File size should be less than 5MB."
            : "Please upload a chat background image. Only JPG, JPEG, PNG formats are accepted. File size should be less than 5MB."}
        </p>
      </div>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert sx={{ width: "100%" }}>{alertInfo.message}</Alert>
      </Snackbar>
    </>
  );
};
