import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  forwardRef,
  useRef,
} from "react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Character } from "../../types";
import { Button } from "@mui/joy";
import { createTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExploreGrid from "../common/ExploreGrid";
import "./HomepageComponents.css";
import { CircularProgress } from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";

type SortOrder = "New" | "Most Popular";

const tags: string[] = [
  "Fantasy",
  "Anime",
  "Celebrities",
  "Comedy",
  "Historical",
  "Horror",
  "Role Play",
  "Sci-Fi",
  "Superheroes",
  "Gaming",
  "LGBTQ+",
  "Original Character",
  "NSFW",
  "Movies & TV",
];

type SortButtonProps = {
  onSortChange: (newSortOrder: SortOrder) => void;
};

function SortButton({ onSortChange }: SortButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Most Popular");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (order: SortOrder) => {
    setSortOrder(order);
    onSortChange(order);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="sort-menu"
        aria-haspopup="true"
        size="sm"
        onClick={handleClick}
        color="primary"
        className="!bg-orange-500"
      >
        SORT: {sortOrder}
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(sortOrder)}
      >
        <MenuItem onClick={() => handleClose("New")}>New</MenuItem>
        <MenuItem onClick={() => handleClose("Most Popular")}>
          Most Popular
        </MenuItem>
      </Menu>
    </div>
  );
}

interface TagsComponentProps {
  onTagsChange: (tags: string[]) => void;
  initialTags: string[];
  sortOrder: SortOrder;
}

type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button
      ref={ref}
      className="absolute left-[10px] top-1 z-10 px-2 opacity-0 cursor-pointer bg-transparent border-none transition-opacity duration-300 hover:opacity-80"
      {...props}
    >
      {!isMobile && <ChevronLeft className="text-orange-500 fill-black" />}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button
      ref={ref}
      className="absolute right-[10px] top-1 z-10 px-2 opacity-0 cursor-pointer bg-transparent border-none text-fourwall-orange transition-opacity duration-300 hover:opacity-80"
      {...props}
    >
      {!isMobile && <ChevronRight className="text-orange-500 fill-black" />}
    </button>
  )
);
const isMobile = window.innerWidth < 768;
CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";
const TagsComponent = ({
  onTagsChange,
  initialTags,
  sortOrder,
}: TagsComponentProps) => {
  const [selectedTags, setSelectedTags] = useState(initialTags);
  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  // const isMobile = window.innerWidth < 768;
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  const toggleTag = (tag: string) => {
    let updatedTags: string[] = [];
    if (selectedTags.includes(tag)) {
      updatedTags = selectedTags.filter((t: string) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    console.log(updatedTags);
    setSelectedTags(updatedTags);
    onTagsChange(updatedTags);
  };
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    onTagsChange(selectedTags);
  }, [selectedTags, sortOrder]);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
        ]}
        touchEventsTarget="container"
        spaceBetween={4}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={400}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={3}
        onSlideChange={(swiper) => {
          setIsEnd(swiper.isEnd);
          setIsBeginning(swiper.isBeginning);
        }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className=""
      >
        <>
          <CustomPrevArrow
            ref={prevRef}
            style={{ opacity: isHovered ? 0.8 : 0 }}
          />
          <CustomNextArrow
            ref={nextRef}
            style={{ opacity: isHovered ? 0.8 : 0 }}
          />
        </>

        {tags.map((tag, index) => (
          <SwiperSlide key={index} className="w-auto">
            <Button
              size="md"
              className={`${selectedTags.includes(tag) ? "!bg-orange-500" : "!bg-gray-700"} !text-white !rounded-md`}
              onClick={() => toggleTag(tag)}
            >
              {tag}
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
      {!isEnd && (
        <div
          className="absolute top-0 right-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
      {!isBeginning && (
        <div
          className="absolute top-0 left-0 bottom-0 w-12 pointer-events-none z-10"
          style={{
            background:
              "linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.6))",
          }}
        ></div>
      )}
    </div>
  );
};

interface ExploreProps {
  handleCharacterSelect: (character: Character) => void;
  isDynamicTheme: boolean;
}

function Explore({ handleCharacterSelect, isDynamicTheme }: ExploreProps) {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [sortOrder, setSortOrder] = useState<SortOrder>("Most Popular");
  const [cloudSearchQuery, setCloudSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState<number>(60);
  const [startIndex, setStartIndex] = useState(0);

  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { NSFW } = context;

  const charactersToShow = useMemo(() => {
    let sortedCharacters = characters;
    if (sortOrder === "Most Popular") {
      sortedCharacters = [...characters].sort(
        (a, b) => b.interactions - a.interactions
      );
    } else if (sortOrder === "New") {
      sortedCharacters = [...characters].sort((a, b) => {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    }
    return sortedCharacters;
  }, [characters]);

  const darkTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: localStorage.getItem("darkMode") === "true" ? "dark" : "light",
        },
      }),
    []
  );

  function parseSearchResults(searchResults: any) {
    const hits = searchResults.hits.hit;
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public } = hit.fields;
      return is_deleted === "0" && is_public === "1";
    });

    return filteredHits.map((hit: any) => ({
      bio: hit.fields.bio,
      char_message_bg_color: hit.fields.char_message_bg_color,
      char_text_color: hit.fields.char_text_color,
      character_id: hit.fields.character_id,
      chat_background_color: hit.fields.chat_background_color,
      creator: hit.fields.creator,
      user_message_bg_color: hit.fields.user_message_bg_color,
      user_text_color: hit.fields.user_text_color,
      name: hit.fields.name,
      image_url: `https://4thwall-assets.s3.amazonaws.com/${hit.fields.character_id}/display_picture`,
      chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${hit.fields.character_id}/chat_background`,
      interactions: hit.fields.num_interactions,
      num_saves: hit.fields.num_favorites,
      tags: hit.fields.tags,
      created_at: hit.fields.created_at,
    }));
  }

  const handleTagSubmit = (currentTags: string[]): void => {
    setLoading(true);
    if (currentTags.length === 0) {
      setCharacters([]);
      setStartIndex(0);
      setLoading(false);
      return;
    }

    let tagsQuery;
    let newCloudSearchQuery;
    let sortParam = "";
    if (sortOrder === "Most Popular") {
      sortParam = "&sort=num_interactions desc";
    } else if (sortOrder === "New") {
      sortParam = "&sort=created_at desc";
    }
    // Encode tags to handle special characters
    const encodedTags = currentTags.map((tag) => encodeURIComponent(tag));

    if (!NSFW) {
      const excludeNsfwQuery = `not (term field=tags 'NSFW')`;
      tagsQuery = encodedTags
        .map((encodedTag) => `(term field=tags '${encodedTag}')`)
        .join(" ");
      newCloudSearchQuery = `q=(and(or ${tagsQuery}) (${excludeNsfwQuery}))&fq=(and is_public:'1' is_deleted:'0')&q.parser=structured`;
    } else {
      tagsQuery = encodedTags
        .map((encodedTag) => `(term field=tags '${encodedTag}')`)
        .join(" ");
      newCloudSearchQuery = `q=(or ${tagsQuery})&fq=(and is_public:'1' is_deleted:'0')&q.parser=structured`;
    }

    newCloudSearchQuery += sortParam;

    setCloudSearchQuery(newCloudSearchQuery);
    fetchInitialCharacters(newCloudSearchQuery);
    setLoading(false);
  };

  const fetchInitialCharacters = (query: string) => {
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    const searchUrl = `${apiUrl}?${query}&start=0&size=${itemsPerPage}&cachebust=${new Date().getTime()}`;
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters(newCharacters);
        // setTotalPages(Math.ceil(newCharacters.length / itemsPerPage));
        setStartIndex(60);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleLoadMoreCharacters = () => {
    const apiUrl = process.env.REACT_APP_SEARCH_API;
    const searchUrl = `${apiUrl}?${cloudSearchQuery}&size=${itemsPerPage}&start=${startIndex}&cachebust=${new Date().getTime()}`;
    fetch(searchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setCharacters((prevCharacters) => [
          ...prevCharacters,
          ...newCharacters,
        ]);
        setStartIndex((prevStartIndex) => prevStartIndex + itemsPerPage);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const isMobile = window.innerWidth < 768;

  return (
    <div className="space-y-3">
      <div className="flex justify-between items-center">
        {isMobile && <h3 className="text-white font-main">EXPLORE</h3>}
        <SortButton onSortChange={setSortOrder} />
      </div>
      <TagsComponent
        initialTags={["Anime"]}
        onTagsChange={handleTagSubmit}
        sortOrder={sortOrder}
      />
      {loading && (
        <div
          style={{
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <ExploreGrid
        characters={charactersToShow}
        onLoadMore={handleLoadMoreCharacters}
        handleCharacterSelect={handleCharacterSelect}
      />
    </div>
  );
}

export default Explore;
