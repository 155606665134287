import React, { useRef, useState, useEffect, FC, forwardRef } from "react";
import {
  Card,
  CardContent,
  CardCover,
  AspectRatio,
  Chip,
  ChipDelete,
} from "@mui/joy";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper-bundle.css";
interface FeaturedSwiperProps {
  maps: { name: string; image: string }[];
  handleMapSelect: (map: { name: string; image: string }) => void;
}
type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

export const MapCard = ({
  map,
  onClick,
  selectedMap,
}: {
  map: { name: string; image: string };
  onClick: () => void;
  selectedMap: { name: string; image: string } | null;
}) => {
  return (
    <Card
      variant="plain"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        width: 180,
        position: "relative",
        borderRadius: "1rem",

        padding: "0rem",
      }}
    >
      <AspectRatio
        ratio="16/9"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "1rem",
        }}
      >
        <CardCover>
          <div
            style={{
              backgroundImage: `url(${map.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.4)",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                color: "white",
                fontSize: "14px",

                fontFamily: "var(--font_b)",
                border: selectedMap === map ? "3px solid red" : "none",
              }}
            >
              {map.name}
            </div>
          </div>
        </CardCover>
      </AspectRatio>
    </Card>
  );
};

function FeaturedSwiper({ maps, handleMapSelect }: FeaturedSwiperProps) {
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);
  const [selectedMap, setSelectedMap] = useState<{
    name: string;
    image: string;
  } | null>(null);
  useEffect(() => {
    const updateSlidesPerGroup = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerGroup(2);
      } else if (width < 1024) {
        setSlidesPerGroup(3);
      } else {
        setSlidesPerGroup(5);
      }
    };

    updateSlidesPerGroup();
    window.addEventListener("resize", updateSlidesPerGroup);

    return () => {
      window.removeEventListener("resize", updateSlidesPerGroup);
    };
  }, []);
  const onMapSelect = (map: { name: string; image: string }) => {
    setSelectedMap(map);
    handleMapSelect(map);
  };
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  return (
    <div
      style={{
        paddingRight: "1rem",
        color: "var(--primary-text-color)",
        fontFamily: "var(--font_b)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <h3 style={{ margin: "0 0.5rem" }}>Maps</h3>
        {selectedMap && (
          <Chip
            variant="outlined"
            color="danger"
            sx={{
              backgroundColor: "var(--orange_brand_accent)",
              color: "var(--primary-text-color)",
            }}
            endDecorator={
              <ChipDelete color="danger" onClick={() => setSelectedMap(null)} />
            }
          >
            {selectedMap?.name}
          </Chip>
        )}
      </div>

      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
        ]}
        spaceBetween={14}
        slidesPerView={"auto"}
        touchRatio={1}
        speed={600}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        slidesPerGroup={2}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper: SwiperInstance) => {
          if (typeof swiper.params.navigation !== "boolean") {
            // Ensure it's not the boolean 'false'
            (swiper.params.navigation as any).prevEl = prevRef.current;
            (swiper.params.navigation as any).nextEl = nextRef.current;
          }
        }}
        className="mySwiper"
      >
        <CustomPrevArrow ref={prevRef} />
        <CustomNextArrow ref={nextRef} />
        {maps.map((map) => (
          <SwiperSlide key={map.name} onClick={() => onMapSelect(map)}>
            <MapCard
              map={map}
              onClick={() => onMapSelect(map)}
              selectedMap={selectedMap}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default FeaturedSwiper;
