import React from "react";
import {
  Input,
  Box,
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
  CircularProgress,
  Avatar,
} from "@mui/joy";
import { Search } from "lucide-react";
import { useState, useEffect, useRef, useContext } from "react";
import { Character } from "../../types";
import { PlatformContext } from "../../contexts/PlatformContext";
import { debounce } from "../Misc/SearchOverlay";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function SearchBar() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Character[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { NSFW } = context;

  function parseSearchResults(searchResults: any) {
    // Extract the hits from the search results
    const hits = searchResults.hits.hit;

    // Log those that are filtered out for debugging
    const filteredHits = hits.filter((hit: any) => {
      const { is_deleted, is_public, tags } = hit.fields;
      const isDeleted = is_deleted !== "0";
      const isNotPublic = is_public !== "1";
      const containsNsfw = tags.includes("NSFW");
      if (isDeleted || isNotPublic || (!NSFW && containsNsfw)) {
        console.log(
          `Character skipped (ID: ${hit.fields.character_id}, Name: ${hit.fields.name}) because is_deleted=${isDeleted}, is_public=${isNotPublic}, and contains NSFW=${containsNsfw}`
        );
        return false;
      }
      return true;
    });

    // Map over the hits array to transform each search hit into a Character object
    const characters = filteredHits.map((hit: any) => {
      const fields = hit.fields;

      return {
        bio: fields.bio,
        char_message_bg_color: fields.char_message_bg_color,
        char_text_color: fields.char_text_color,
        character_id: fields.character_id,
        chat_background_color: fields.chat_background_color,
        creator: fields.creator,
        user_message_bg_color: fields.user_message_bg_color,
        user_text_color: fields.user_text_color,
        name: fields.name,
        image_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/display_picture`,
        chat_background_url: `https://4thwall-assets.s3.amazonaws.com/${fields.character_id}/chat_background`,
        interactions: fields.num_interactions,
        num_saves: fields.num_favorites,
        tags: fields.tags,

        // ... map all other fields to their respective Character properties
      };
    });

    return characters;
  }

  const fetchSearchResults = (value: string) => {
    setIsLoading(true);

    const apiUrl = `${process.env.REACT_APP_SEARCH_API}?q=${encodeURIComponent(
      value + "*"
    )}&fq=(and is_public:'1' is_deleted:'0')&start=0&size=50`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const newCharacters = parseSearchResults(data);
        setSearchResults(newCharacters);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Search error:", error);
        setIsLoading(false);
      });
  };

  const debouncedSearch = useRef(debounce(fetchSearchResults, 300)).current;
  const handleCharacterSelect = (character: Character) => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: `/chat/${character.character_id}`,
        },
      });
      return;
    }
    navigate(`/chat/${character.character_id}`);
  };
  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm]);

  const formatInteractions = (interactions: number) => {
    if (interactions >= 1000000) {
      return (interactions / 1000000).toFixed(1) + "M";
    } else if (interactions >= 1000) {
      return (interactions / 1000).toFixed(1) + "K";
    } else {
      return interactions.toString();
    }
  };

  return (
    <div className="relative h-10 w-64 md:w-96 z-50">
      <Input
        startDecorator={<Search className="text-orange-400" />}
        id="searchCharacters"
        placeholder="Search for Characters"
        type="search"
        fullWidth
        value={searchTerm}
        className="!bg-gray-800 !border-orange-400  !z-50 !flex !gap-2 !w-full !max-w-3xl !border-spacing-1 !rounded-3xl !bg-surface-elevation-1 !p-2  focus:!outline-orange-400 !text-orange-100"
        onChange={(e) => setSearchTerm(e.target.value)}
        autoFocus={false}
      />

      {searchResults.length > 0 && searchTerm.length > 0 && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setSearchTerm("")}
          ></div>
          <Box className="!rounded-3xl !bg-gray-800 !mt-2 !overflow-y-auto !max-h-[calc(100vh-5rem)] !shadow-lg !z-50 !relative ">
            <List
              sx={{
                "--ListItemDecorator-size": "56px",
                overflowY: "auto",
              }}
              className="!font-chat !overflow-y-auto !scrollbar-thin !scrollbar-thumb-[var(--orange-brand-accent)] !scrollbar-track-transparent space-y-2 !p-1"
            >
              {isLoading ? (
                <CircularProgress size="md" thickness={4} />
              ) : (
                searchResults.map((character) => (
                  <ListItem
                    key={character.character_id}
                    className="p-1 hover:bg-gray-700 transition-colors duration-100 cursor-pointer !rounded-xl !m-2"
                    onClick={() => {
                      // handleCharacterSelect(character);
                      navigate(`/chat/${character.character_id}`);
                    }}
                  >
                    <ListItemDecorator>
                      <Avatar
                        size="lg"
                        alt={character.name}
                        src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                      />
                    </ListItemDecorator>
                    <ListItemContent className="space-y-1 ">
                      <p className="text-orange-100 m-0">{character.name}</p>
                      <p className="text-orange-200 text-xs line-clamp-2">
                        {character.bio}
                      </p>
                      <p
                        className="text-orange-400 text-xs cursor-pointer hover:scale-105 transition-transform"
                        onClick={() => {
                          navigate(`/profile/${character.creator}`);
                        }}
                      >
                        {formatInteractions(character.interactions)} chats @
                        {character.creator}
                      </p>
                    </ListItemContent>
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        </>
      )}
    </div>
  );
}

export default SearchBar;
