import React, { useState, ChangeEvent } from "react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  Button,
  FormControl,
  ModalClose,
  FormLabel,
  FormHelperText,
  Avatar,
  Textarea,
  Alert,
  Snackbar,
  Switch,
} from "@mui/joy";
import useRequest from "../../apis/useRequest";
import { Backdrop, CircularProgress } from "@mui/material";
import { PersonaDataProps, UserAssets } from "../../types";
import { v4 as uuidv4 } from "uuid";

import {
  createPersona,
  updatePersona,
  getPersona,
  getUserAssets,
  uploadImage,
} from "../../apis/request";
import { useAuth0 } from "@auth0/auth0-react";
import { PlatformContext } from "../../contexts/PlatformContext";
import { Pencil, Check, X } from "lucide-react";

interface CreateUpdatePersonaProps {
  persona: PersonaDataProps;
  handleCloseForm: () => void;
  showCreatePersona: boolean;
  setShowCreatePersona: (showCreatePersona: boolean) => void;
  setPersona: (persona: any) => void;
  setPersonas: (personas: any) => void;
  setUserAssets: (userAssets: any) => void;
}

function CreateUpdatePersona({
  persona,
  handleCloseForm,
  showCreatePersona,
  setShowCreatePersona,
  setPersona,
  setPersonas,
  setUserAssets,
}: CreateUpdatePersonaProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ name: false, description: false });
  const { user, isAuthenticated } = useAuth0();
  const [uploading, setUploading] = useState(false);
  const [modMessage, setModMessage] = useState("");
  const [openModerator, setOpenModerator] = useState(false);
  const [confirmMode, setConfirmMode] = useState(false);
  const [isCreatePersona, setIsCreatePersona] = useState(
    persona.persona_id === ""
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChange =
    (prop: keyof PersonaDataProps) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setPersona({ ...persona, [prop]: value });
      if (value.trim() !== "") {
        setErrors({ ...errors, [prop]: false });
      }
    };

  const handleDefaultSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPersona({ ...persona, default: event.target.checked });
  };

  const context = React.useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a CharactersContext.Provider"
    );
  }
  const { username, alertInfo, showAlert, closeAlert } = context;

  const refetchUserAssets = async (username: string) => {
    const userRequest = getUserAssets(username, "false");
    try {
      const response = await makeRequest<UserAssets, any>(userRequest);
      setUserAssets(response);
    } catch (error) {
      console.error("There was an error fetching user assets:", error);
    }
  };
  const makeRequest = useRequest();
  const handleSubmitForm = async () => {
    if (persona.name.trim() === "" || persona.description.trim() === "") {
      setErrors({
        name: persona.name.trim() === "",
        description: persona.description.trim() === "",
      });
      showAlert("Name and description cannot be empty", "danger");
      return;
    }
    setIsSubmitting(true);
    const newPersonaId =
      persona.persona_id === "" ? uuidv4() : persona.persona_id;
    const userRequest = isCreatePersona
      ? createPersona(newPersonaId)
      : updatePersona(newPersonaId);
    const request_body = {
      persona: {
        name: persona.name,
        description: persona.description,
        persona_id: newPersonaId,
        default: persona.default,
        username: username,
      },
    };
    try {
      const response = await makeRequest<PersonaDataProps, any>(
        userRequest,
        request_body
      );
      showAlert("Persona created/updated successfully!", "success");
      await refetchUserAssets(username);
      setShowCreatePersona(false);
    } catch (error) {
      console.error("There was an error creating/updating the persona:", error);
      showAlert("Failed to create/update persona. Please try again.", "danger");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleImageUpload = async (file: File) => {
    if (!file.type.match(/^image\/(jpeg|jpg|png)$/i)) {
      showAlert("Please upload a JPG, JPEG or PNG image file.", "danger");
      return;
    }
    setUploading(true);
    const formData = new FormData();
    const personaId = persona.persona_id === "" ? uuidv4() : persona.persona_id;
    formData.append("image", file);
    formData.append("asset", "persona");
    formData.append("identifier_id", personaId);
    const response = await makeRequest<any, any>(uploadImage(), formData);
    if (!response.is_safe) {
      setModMessage(response.reasons);
      setOpenModerator(true);
    } else {
      showAlert("Image uploaded successfully", "success");
      setPersona({ ...persona, persona_id: personaId });
    }
    setUploading(false);
  };
  const LoadingOverlay = ({ open }: { open: boolean }) => (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return (
    <div>
      {" "}
      <Modal open={showCreatePersona} onClose={handleCloseForm}>
        <ModalDialog className="!bg-neutral-700 !border-none !text-orange-500 !font-main !min-w-[30%]">
          <DialogTitle className="!text-orange-500 !font-main !text-md md:!text-2xl">
            {persona.name ? "Update" : "Create"} Persona
          </DialogTitle>
          <ModalClose />
          <DialogContent className="!text-orange-100 !p-1 space-y-12">
            <div className="flex md:flex-row flex-col items-center justify-between">
              {uploading ? (
                <CircularProgress className="!text-orange-500 !border-none !outline-none" />
              ) : (
                <div
                  className={`relative flex flex-col ${confirmMode ? " border-fourwall-orange p-2 m-2" : ""}`}
                >
                  <Avatar
                    src={
                      confirmMode && selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : persona.persona_id
                          ? `${process.env.REACT_APP_ASSETS_BUCKET}personas/${persona.persona_id}/display_picture.jpg?${Date.now()}`
                          : undefined
                    }
                    alt={persona.name}
                    className="!w-32 !h-32"
                  />

                  {!confirmMode ? (
                    <label
                      htmlFor="persona-image-upload"
                      className="absolute bottom-1 right-1 p-2 text-white bg-gray-800/90 rounded-xl cursor-pointer hover:bg-fourwall-orange transition-all duration-100"
                    >
                      <input
                        id="persona-image-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            if (file.size <= 5 * 1024 * 1024) {
                              // 5MB in bytes
                              setSelectedFile(file);
                              setConfirmMode(true);
                            } else {
                              // File is too large, don't show alert
                              showAlert(
                                "File size exceeds the limit of 5MB",
                                "danger"
                              );
                              e.target.value = ""; // Clear the file input
                            }
                          }
                        }}
                      />

                      <Pencil size={22} />
                    </label>
                  ) : (
                    <div className="space-x-2 rounded-full mt-1 flex items-center justify-center  bg-opacity-50">
                      <Button
                        color="success"
                        variant="soft"
                        onClick={() => {
                          if (selectedFile) {
                            handleImageUpload(selectedFile);
                          }
                          setConfirmMode(false);
                        }}
                      >
                        <Check size={22} />
                      </Button>
                      <Button
                        variant="soft"
                        color="danger"
                        onClick={() => {
                          setSelectedFile(null);
                          setConfirmMode(false);
                        }}
                      >
                        <X size={22} />
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <FormControl className="m-1 !font-main">
                <FormLabel
                  htmlFor="default-switch"
                  className="flex items-center"
                >
                  <Switch
                    id="default-switch"
                    checked={persona.default}
                    onChange={handleDefaultSwitchChange}
                  />
                  <span className="text-xs md:text-base lg:text-lg ml-2 text-orange-200">
                    Set as default for chats
                  </span>
                </FormLabel>
              </FormControl>
            </div>
            <FormControl error={errors.name}>
              <FormLabel className="!text-white">Name</FormLabel>
              <Input
                className="!text-white !bg-neutral-800 !border-none !outline-none "
                value={persona.name}
                color="warning"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value.slice(0, 30);
                  handleChange("name")({
                    ...e,
                    target: { ...e.target, value: newValue },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
              />
              <FormHelperText className="!text-orange-100">{`${persona.name.length}/30`}</FormHelperText>
              {errors.name && (
                <FormHelperText className="!text-red-500">
                  Name cannot be empty
                </FormHelperText>
              )}
            </FormControl>

            <FormControl error={errors.description}>
              <FormLabel className="!text-white">Description</FormLabel>
              <Textarea
                className="!text-white !bg-neutral-800 !border-none !outline-none "
                value={persona.description}
                color="warning"
                minRows={4}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const newValue = e.target.value.slice(0, 1000);
                  handleChange("description")({
                    ...e,
                    target: { ...e.target, value: newValue },
                  } as React.ChangeEvent<HTMLTextAreaElement>);
                }}
              />
              <FormHelperText className="!text-orange-100">{`${persona.description.length}/1000`}</FormHelperText>
              {errors.description && (
                <FormHelperText className="!text-red-500">
                  Description cannot be empty
                </FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              className="!text-white !rounded-lg !font-main !bg-orange-500"
              onClick={handleSubmitForm}
            >
              Save
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={3000}
        onClose={closeAlert}
      >
        <Alert sx={{ width: "100%" }}>{alertInfo.message}</Alert>
      </Snackbar>
      <LoadingOverlay open={isSubmitting} />
      <Modal open={openModerator} onClose={() => setOpenModerator(false)}>
        <ModalDialog className="!bg-neutral-900 !text-white !border-none ">
          <ModalClose />
          <div className="flex flex-col space-y-2 items-center">
            <h2 className="text-2xl font-bold font-main text-fourwall-orange">
              Sorry, please choose a different image
            </h2>
            <p className="font-main">{modMessage}</p>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default CreateUpdatePersona;
