import React, { createContext, useContext, useState } from "react";
import { Viewport } from "pixi-viewport";

const ViewportContext = createContext<{
  viewport: Viewport | null;
  setViewport: React.Dispatch<React.SetStateAction<Viewport | null>>;
}>({ viewport: null, setViewport: () => {} });

export const ViewportProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [viewport, setViewport] = useState<Viewport | null>(null);
  return (
    <ViewportContext.Provider value={{ viewport, setViewport }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => useContext(ViewportContext);
