import React from "react";
import { ServerGame } from "../../hooks/serverGame";
import { GameId } from "../../convex/aiTown/ids";
import { SelectElement } from "./Player";
import * as PIXI from "pixi.js";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { Chip } from "@mui/joy";
import { useViewport } from "../../contexts/ViewportContext";

export interface GodviewPlayerDetailsProps {
  worldId: Id<"worlds">;
  playerId: GameId<"players">;
  game: ServerGame;
  setSelectedElement: SelectElement;
  onPlayerClick: (player: GameId<"players">) => void;
}
function GodviewPlayerDetails({
  worldId,
  playerId,
  game,

  onPlayerClick,
}: GodviewPlayerDetailsProps) {
  const players = [...(game?.world.players.values() || [])];
  const player = game.world.players.get(playerId);
  const playerConversation = player && game.world.playerConversation(player);
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const playerDescription = playerId && game.playerDescriptions.get(playerId);
  const playerStatus =
    playerConversation && playerConversation.participants.get(playerId)?.status;
  const participants = playerConversation && playerConversation.participants;
  const otherParticipants =
    participants &&
    [...participants.values()].filter((p) => p.playerId !== playerId);
  const otherParticipantsIds = otherParticipants?.map((p) => p.playerId);

  const otherParticipantsNames = otherParticipantsIds?.map(
    (id) =>
      descriptions?.playerDescriptions.find((p: any) => p.playerId === id)?.name
  );
  const action =
    playerStatus?.kind === "walkingOver"
      ? "walking over to"
      : playerStatus?.kind === "invited"
        ? "invited"
        : playerStatus?.kind === "participating"
          ? "talking to"
          : "";
  const { viewport } = useViewport();
  const { width, height, tileDim } = game.worldMap;
  const handlePlayerClick = () => {
    onPlayerClick(playerId);
  };
  if (!player) {
    return null;
  }
  return (
    <div
      key={player.id}
      className="flex flex-row items-center gap-2"
      onClick={handlePlayerClick}
    >
      <img
        style={styles.charImg}
        src={playerDescription?.image || "/chatdp.png"}
        alt={player.id}
      />
      <p className={`text-sm hover:text-fourwall-orange`}>
        {playerDescription?.name}
      </p>
      {playerStatus && (
        <Chip variant="outlined" color="danger">
          {action} {otherParticipantsNames?.join(", ")}
        </Chip>
      )}
      {!playerConversation &&
        player.activity &&
        player.activity.until > Date.now() && (
          <Chip variant="outlined" color="neutral">
            {player.activity?.description}
          </Chip>
        )}
    </div>
  );
}

const styles: Record<string, React.CSSProperties> = {
  charImg: {
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
  },
};
export default GodviewPlayerDetails;
