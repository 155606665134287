import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import Game from "../Town/Game";
import "./TownPage.css";

function Walltown() {
  const { town_id } = useParams<{ town_id: string }>();
  const location = useLocation();
  const townName = location.state?.townName;
  if (!town_id) {
    throw new Error("town_id is undefined");
  }
  const castTownId: Id<"worlds"> = town_id as Id<"worlds">;
  const result = useQuery(api.world.getWorldStatus, {
    worldId: castTownId,
  });
  const worldStatus = result?.worldStatus;

  return (
    <>
      <Game worldStatus={worldStatus} townName={townName} />
    </>
  );
}

export default Walltown;
