import { useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Modal,
  ModalDialog,
  ModalClose,
  Typography,
  Box,
  TextField,
} from "@mui/joy";
import { TriangleAlert } from "lucide-react";
import { FormControlLabel, Switch } from "@mui/material";
import { deleteUser } from "../../apis/request";
import { PlatformContext } from "../../contexts/PlatformContext";
import useRequest from "../../apis/useRequest";
interface SettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

function SettingsDialog({ open, onClose }: SettingsDialogProps) {
  const { logout } = useAuth0();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error("PlatformContext not found");
  }
  const { username, NSFW, setNSFW } = context;
  const makeRequest = useRequest();

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    // Implement account deletion logic here
    const response = await makeRequest<void, void>(deleteUser(username));
    console.log(response);
    onClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog className="!bg-neutral-800 !text-white !border-none !rounded-lg !p-4 !font-main">
          <ModalClose />

          <div className="flex flex-col space-y-4 m-10 items-center ">
            <FormControlLabel
              control={
                <Switch
                  checked={NSFW}
                  onChange={() => setNSFW(!NSFW)}
                  className="!text-white"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="NSFW"
              className="!text-white"
            />
            <Button
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
              className="!font-main !bg-gray-700 !text-gray-300 w-full hover:!bg-gray-600"
            >
              Logout
            </Button>
            <Button
              variant="outlined"
              onClick={handleDeleteClick}
              color="danger"
              className=" !font-main w-full"
            >
              Delete Account <TriangleAlert className="w-4 h-4 px-2" />
            </Button>
          </div>
        </ModalDialog>
      </Modal>

      <Modal open={showDeleteConfirmation} onClose={handleDeleteCancel}>
        <ModalDialog className="!bg-gray-800 !text-white !border-none !rounded-lg !p-4 !font-main">
          <ModalClose />
          <Typography level="h4" className="mb-4 !text-white">
            Are you sure you want to delete your account?
          </Typography>
          <Typography className="mb-4 !text-white !text-white">
            Deleting your account is permanent and will remove all your data,
            including your characters, worlds, and interactions, from our
            servers. This action cannot be undone.
          </Typography>
          <Typography className="!text-gray-300 !text-xs !italic !font-main">
            Please note: After you delete your account, you will not be able to
            recover any of your data.
          </Typography>
          <Box className="flex justify-end space-x-2">
            <Button
              onClick={handleDeleteConfirm}
              color="danger"
              className="!font-main"
            >
              Delete Account
            </Button>
            <Button
              onClick={handleDeleteCancel}
              className="!font-main !bg-gray-700 !text-gray-300"
            >
              Cancel
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}

export default SettingsDialog;
