import React, { useState, forwardRef } from "react";
import { Swiper as SwiperInstance } from "swiper/types"; // Importing the type for Swiper instance
import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  A11y,
} from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper-bundle.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import SocialCard from "./SocialCard";

type ArrowProps = React.HTMLAttributes<HTMLButtonElement>;

const CustomPrevArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-prev" {...props}>
      &#10094; {/* Left arrow symbol */}
    </button>
  )
);

const CustomNextArrow = forwardRef<HTMLButtonElement, ArrowProps>(
  (props, ref) => (
    <button ref={ref} className="custom-swiper-button-next" {...props}>
      &#10095; {/* Right arrow symbol */}
    </button>
  )
);

CustomPrevArrow.displayName = "CustomPrevArrow";
CustomNextArrow.displayName = "CustomNextArrow";

function NewsCarousel({}) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  const handleClickRequiringSignUp = (route: string) => {
    if (isAuthenticated) {
      navigate(route);
    } else {
      loginWithRedirect({
        appState: { returnTo: route },
      });
    }
  };
  return (
    <div className="w-full mt-4">
      <Swiper
        modules={[
          FreeMode,
          Mousewheel,
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
        ]}
        style={{ height: "26rem" }}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        touchEventsTarget="container"
        // slidesPerView={"auto"}
        touchRatio={1}
        speed={600}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        mousewheel={{
          forceToAxis: true,
          releaseOnEdges: true,
        }}
        freeMode={true}
        pagination={{
          clickable: true,
          // renderBullet: function (index, className) {
          //   return (
          //     '<span class="' +
          //     className +
          //     '"><img src="/images/reddit.png" alt="Reddit" /></span>'
          //   );
          // },
          type: "bullets",
        }}
        className={`swiper-container ${window.innerWidth <= 768 ? "max-h-[11rem]" : ""}`}
        allowTouchMove={window.innerWidth <= 768} // Disable swiping if not on mobile
        simulateTouch={window.innerWidth <= 768} // Enable swiping if on mobile
      >
        {[
          { className: "chat-characters-slide" },
          {
            className: "reddit-slide",
            content: <SocialCard url="https://www.reddit.com/r/4WallAI/" />,
          },
          {
            className: "create-character-slide",
            onClick: () => handleClickRequiringSignUp("/create"),
          },
          // {
          //   className: "create-botcast-slide",
          //   onClick: () => handleClickRequiringSignUp("/botcast/create"),
          // },
          {
            className: "discord-slide",
            content: <SocialCard url="https://discord.gg/EUebsQR5KE" />,
            onClick: () => {},
          },
          // {
          //   className: "town-ad-slide",
          //   onClick: () =>
          //     window.open("https://discord.gg/EUebsQR5KE", "_blank"),
          // },
        ].map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              className={`slide ${slide.className} justify-center rounded-lg transition-colors duration-200`}
              onClick={slide.onClick}
              style={slide.content ? { display: "flex" } : {}}
            >
              {slide.content}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default NewsCarousel;
